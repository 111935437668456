import BaseService from './baseService';
import { ScenarioStatusFilter } from 'store/scenarioStatus/types';

export default class extends BaseService {
    constructor() {
        super('scenarioStatus');
    }

    /**
    * Gets list of history items
    * @param {Object<ScenarioStatusFilter>} filter - historyId
    * @returns list of Scenario Statuses
    */
    getStatuses = (filter: ScenarioStatusFilter) => this.httpPostRequest('list', filter);

    /**
    * Gets logs urls
    * @param {number} historyId - historyId
    * @returns string url
    */
    getLogs = (historyId: number) => this.httpGetRequest(`logs/${historyId}`);

    /**
    * Gets errorLogs urls
    * @param {number} historyId - historyId
    * @returns string url
    */
    getErrorLogs = (historyId: number) => this.httpGetRequest(`errorLogs/${historyId}`);

    /**
    * Gets results upload url
    * @param {number} historyId - historyId
    * @returns string url
    */
    getResults = (historyId: number) => this.httpGetRequest(`results/${historyId}`);

    /**
    * Gets errors for segments
    * @param {number} historyId - historyId
    */
    restartSegment = (historyId: number) => this.httpPostRequest(`restart/${historyId}`);

    /**
    * Restart failed segments
    * @param {number} scenarioId - scenarioId
    */
    restartFailedSegments = (scenarioId: number) => this.httpPostRequest(`restartSegments/${scenarioId}`);

    /**
    * Stops segments
    * @param {number} historyId - historyId
    */
    stopSegment = (historyId: number) => this.httpPostRequest(`stop/${historyId}`);

    /**
    * Sets manual error run status for specified case segments
    * @param {Object} params
    * @param {number} params.scenarioId - scenarioId
    * @param {Array<number>} params.segmentIds - segmentIds
    */
    manualError = ({ scenarioId, segmentIds }: { scenarioId: number; segmentIds: number[] }) => this.httpPostRequest(`manualErrors/${scenarioId}`, segmentIds);

    /**
    * Get adjoin scenario ids for specified scenario
    * @param {number} scenarioId - scenarioId
    * @returns list of Adjoined Scenarios
    */
    getAdjoinScenarios = (scenarioId: number) => this.httpGetRequest(`adjoinScenarios/${scenarioId}`);
}
