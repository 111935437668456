import { fetch } from 'common/helpers';
import { projectSvc as service } from "services/serviceStorage";
import { BaseApiResponse, Project } from 'common/types';
import {
    FetchProjectsParams,
    SaveProjectParams,
    GetProjectParams
} from './types';

export const fetchProjects = function ({ filter, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchProjectsParams) {
    return fetch({
        spinnerToggle,
        callback: (r: BaseApiResponse<{ items: Project[]; totalCount: number }>) =>
            successCallback({
                data: r.result.items.map(p => ({ key: p.id, ...p })),
                total: r.result.totalCount,
            }),
        request: () => service.getProjects(filter),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });
};

export const saveProject = function ({ project, spinnerToggle, successCallback, errorCallback, validationCallback }: SaveProjectParams) {
    return fetch({
        spinnerToggle,
        request: () => service.putProject(project),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });
};

export const getProject = function ({ projectId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetProjectParams) {
    return fetch({
        spinnerToggle,
        request: () => service.getProject(projectId),
        callback: (r: BaseApiResponse<Project>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });
};

export const deleteProject = function ({ projectId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetProjectParams) {
    return fetch({
        spinnerToggle,
        request: () => service.deleteProject(projectId),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });
};

export const archiveProject = function ({ projectId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetProjectParams) {
    return fetch({
        spinnerToggle,
        request: () => service.archiveProject(projectId),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });
};

export const forceDeleteProject = function ({ projectId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetProjectParams) {
    return fetch({
        spinnerToggle,
        request: () => service.forceDeleteProject(projectId),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });
};