import { displayErrors, parseNotifications } from "common/helpers";
import axios from "axios";
import { ErrorResponse } from "./types";

const INTERNAL_ERROR_MESSAGE = 'Internal server error. Please, contact system administrator.';

export const wrapRequest = async <T>({ handler, showNotifications }: {
    handler: () => Promise<{ data: T & ErrorResponse }>;
    showNotifications: boolean;
}): Promise<{ data?: T; errors?: string[]; warnings?: string[]; infos?: string[] }> => {
    try {
        const response = await handler();

        showNotifications && parseNotifications({
            warnings: response.data?.warnings,
            errors: response.data?.errors,
            infos: response.data?.infos
        });

        return { data: response.data };
    } catch (reason) {
        if (axios.isAxiosError(reason) && reason.response) {
            const { status } = reason.response;

            handleStatusCode(status);

            if (status >= 400 && status < 500 && status !== 403) {
                const err = reason.response?.data as ErrorResponse ?? {};

                const errors = (err.errors || [err.error]) ?? [];
                const warnings = (err.validationErrors || err.warnings) ?? [];
                const infos = err.infos ?? [];


                showNotifications && parseNotifications({ errors, warnings, infos })

                return { errors, warnings, infos };
            };

            if (status >= 500) {
                showNotifications && displayErrors([INTERNAL_ERROR_MESSAGE]);
                return { errors: [INTERNAL_ERROR_MESSAGE] };
            }
        }

        throw reason;
    }
};

export function handleStatusCode(status: number) {
    if (status === 403) window.location.href = "/forbidden";
};