import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { store, history, connection } from "./store";
import "ui-assets";
import { Route } from "react-router-dom";
import LayoutContainer from "containers/LayoutContainer";

import { Router, Switch } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import AuthRoute from "./components/common/PrivateRoute";
import MomentLocalizer from "react-widgets-moment";
import moment from "moment";
import Localization from "react-widgets/Localization";
import { useMsal } from '@azure/msal-react';

import { loginRequest } from './config';

const localizer = new MomentLocalizer(moment);

toast.configure();

//avoid extra rerender children components
const SingOutWrapper = ({ children }) => {
    const { instance } = useMsal();

    const signOut = () => {
        localStorage.removeItem("token");
        instance.logout(loginRequest);
    };

    return children(signOut);
};

export default () => (
    <Provider store={store}>
        <Localization date={localizer}>
            <Router history={history}>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <AuthRoute
                            path="/"
                            component={() => (
                                <SingOutWrapper>
                                    {(signOut) => <LayoutContainer hubConnection={connection} signOut={signOut} />}
                                </SingOutWrapper>
                            )}
                        />
                    </Switch>

                    <ToastContainer enableMultiContainer containerId={"A"} position={toast.POSITION.BOTTOM_CENTER} />
                </Suspense>
            </Router>
        </Localization>
    </Provider>
);
