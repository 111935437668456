import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { archivedFilterStates } from "common/enums";
import {
    TaskMgmtState,
    TaskGridFilter,
    ArchiveModalItem,
    EditModalState,
    TaskCopyModalSource,
    TaskCopyLookups
} from "./types";
import { Task } from "common/types";


const initialState: TaskMgmtState = {
    showGridSpinner: false,
    modalHeader: "Edit task",
    gridSource: {
        data: [],
        total: 0,
        filter: {
            searchString: null,
            pageNumber: 1,
            pageSize: 30,
            activeStates: [archivedFilterStates.active]
        }
    },
    deleteModal: {
        open: false,
        taskId: null,
        taskName: null
    },
    archiveModal: {
        open: false,
        taskId: null,
        taskName: null,
        item: {
            archiveResults: 1,
            archiveDays: null
        }
    },
    project: null,
    editModal: {
        isOpen: false,
        spinner: false,
        partitioning: [],
        timeUnits: [],
        releases: [],
        marketModels: [],
        cubeTemplates: [],
        reportTemplates: [],
        vmGroups: [],
        leads: [],
        dashboards: [],
        dashboardGroups: [],
        task: null,
        projectId: null,
        useSpot: false
    },
    marketModelEdit: {
        isOpen: false,
        spinner: false,
        source: {
            taskId: 0,
            marketModelName: '',
        }
    },
    copyModal: {
        isOpen: false,
        spinner: false,
        source: null,
        lookups: null,
    }
};

export const taskMgmtSlice = createSlice({
    name: "taskManagement",
    initialState,
    reducers: {
        setGridSource(state, action: PayloadAction<{ data: Task[]; total: number; filter: TaskGridFilter }>) {
            state.gridSource.data = action.payload.data;
            state.gridSource.total = action.payload.total;
            state.gridSource.filter = action.payload.filter;
        },
        changeFilter(state, action: PayloadAction<Partial<TaskGridFilter>>) {
            state.gridSource.filter = { ...state.gridSource.filter, ...action.payload }
        },
        toggleGridSpinner(state, action: PayloadAction<boolean>) {
            state.showGridSpinner = action.payload;
        },
        setModalHeader(state, action: PayloadAction<string>) {
            state.modalHeader = action.payload;
        },
        setProject(state, action: PayloadAction<any>) {
            state.project = action.payload;
        },
        openDeleteModal(state, action: PayloadAction<{ taskId: number; taskName: string }>) {
            state.deleteModal.open = true;
            state.deleteModal.taskId = action.payload.taskId;
            state.deleteModal.taskName = action.payload.taskName;
        },
        closeDeleteModal(state) {
            state.deleteModal = initialState.deleteModal;
        },
        openArchiveModal(state, action: PayloadAction<{ taskId: number; taskName: string }>) {
            state.archiveModal.open = true;
            state.archiveModal.taskId = action.payload.taskId;
            state.archiveModal.taskName = action.payload.taskName;

            state.archiveModal.item = { ...initialState.archiveModal.item };
        },
        closeArchiveModal(state) {
            state.archiveModal = initialState.archiveModal;
        },
        changeArchiveModalFields(state, action: PayloadAction<Partial<ArchiveModalItem>>) {
            state.archiveModal.item = { ...state.archiveModal.item, ...action.payload };
        },
        resetGridData(state) {
            state.gridSource.data = [];
            state.gridSource.total = 0;
            state.gridSource.filter = {
                ...state.gridSource.filter,
                pageNumber: 1,
                activeStates: [archivedFilterStates.active]
            };
        },
        taskEditModalOpen(state) {
            state.editModal.isOpen = true;
        },
        taskEditModalClose(state) {
            state.editModal = initialState.editModal;
        },
        toggleEditModalSpinner(state, action: PayloadAction<boolean>) {
            state.editModal.spinner = action.payload;
        },
        taskEditModalOpenWithLookups(state, action: PayloadAction<Partial<EditModalState>>) {
            state.editModal = {
                ...state.editModal,
                isOpen: true,
                ...action.payload
            };
        },
        openMarketModalModal(state, action: PayloadAction<number>) {
            state.marketModelEdit.isOpen = true;
            state.marketModelEdit.source.taskId = action.payload;
        },
        closeMarketModalModal(state) {
            state.marketModelEdit = initialState.marketModelEdit;
        },
        changeMarketModalModalFields(state, action: PayloadAction<Partial<{ taskId: number; marketModelName: string; spinner: boolean }>>) {
            if (action.payload.spinner !== undefined) state.marketModelEdit.spinner = action.payload.spinner;
            else state.marketModelEdit.source = { ...state.marketModelEdit.source, ...action.payload };
        },
        openCopyModal(state, action: PayloadAction<{ source: TaskCopyModalSource; lookups: TaskCopyLookups }>) {
            state.copyModal.source = action.payload.source;
            state.copyModal.lookups = action.payload.lookups;
            state.copyModal.isOpen = true;
        },
        closeCopyModal(state) {
            state.copyModal = initialState.copyModal;
        },
        changeCopyModalFields(state, action: PayloadAction<Partial<TaskCopyModalSource & { spinner: boolean }>>) {
            if (action.payload.spinner !== undefined) state.copyModal.spinner = action.payload.spinner;
            else if (state.copyModal.source) {
                state.copyModal.source = {
                    ...state.copyModal.source,
                    ...Object.fromEntries(
                        Object.entries(action.payload).map(([key, value]) => [
                            key,
                            value !== undefined ? value : state.copyModal.source?.[key as keyof TaskCopyModalSource],
                        ])
                    ),
                }
            };
        },
        resetState(state) {
            // reset state except page size
            state = {
                ...initialState,
                gridSource: {
                    ...initialState.gridSource,
                    filter: {
                        ...initialState.gridSource.filter,
                        pageSize: state.gridSource.filter.pageSize,
                    },
                },
            };
        }
    }
});

export const actions = taskMgmtSlice.actions;
export default taskMgmtSlice.reducer;

