import BaseService from './baseService';

export default class DashboardGroupsService extends BaseService {
    constructor() {
        super('dashboard-groups');
    }

    /**
     * Fetches a dashboard group by ID
     * @param {string} groupId - GUID of the dashboard group
     * @returns Dashboard group view item
     */
    getDashboardGroup = (groupId: string) => this.httpGetRequest(`${groupId}`);


    /**
     * Fetches the list of dashboard groups
     * @param {Object} filters - Filters for dashboard groups
     * @param {Array<string>} [filters.groupIds] - Array of group IDs (GUIDs)
     * @param {Array<number>} [filters.releaseGroupIds] - Array of release group IDs
     * @param {number} [filters.pageNumber] - Page number for pagination
     * @param {number} [filters.pageSize] - Page size for pagination
     *  @param {string} [filters.searchString] - Page size for pagination
     * @returns Paginated list of dashboard groups
     */
    getDashboardGroupsList = (filters: Partial<{
        groupIds: Array<string>;
        releaseGroupIds: Array<number>;
        pageNumber: number;
        pageSize: number;
        searchString: string;
    }> = {}) => {
        const queryString = this.generateQueryString({
            g: filters.groupIds,
            rg: filters.releaseGroupIds,
            page: filters.pageNumber,
            size: filters.pageSize,
            ss: filters.searchString,
        });
        return this.httpGetRequest(`list?${queryString}`);
    };

    /**
     * Creates a new dashboard group
     * @param {Object} groupData - Data for the new dashboard group
     * @param {number} [groupData.releaseGroupId] - Release group ID
     * @param {string} [groupData.groupName] - Group name
     * @param {string} [groupData.groupId] - Group ID
     * @param {Array<string>} [groupData.dashboardIds] - Array of dashboard ids
     * @returns GUID of the created dashboard group
     */
    createDashboardGroup = (groupData: Partial<{
        releaseGroupId: number;
        groupName: string;
        groupId: string;
        dashboardIds: string[]
    }>) => this.httpPostRequest('', groupData);

    /**
     * Updates an existing dashboard group
     * @param {Object} groupData - Data for the new dashboard group
     * @param {number} [groupData.releaseGroupId] - Release group ID
     * @param {string} [groupData.groupName] - Group name
     * @param {string} [groupData.groupId] - Group ID
     * @param {Array<string>} [groupData.dashboardIds] - Array of dashboard ids
     * @returns GUID of the updated dashboard group
     */
    updateDashboardGroup = (groupData: Partial<{
        releaseGroupId: number;
        groupName: string;
        groupId: string;
        dashboardIds: string[]
    }>) => this.httpPutRequest('', groupData);

    /**
     * Deletes dashboard groups
     * @param {Array<string>} groupIds - Array of group IDs (GUIDs)
     */
    deleteDashboardGroups = (groupIds: Array<string>) => this.httpDeleteRequest(`?${this.generateQueryString({ id: groupIds })}`);

    /**
     * Adds dashboard links to a group
     * @param {string} groupId - Group ID
     * @param {Array<string>} dashboardIds - Array of dashboard IDs to add
     */
    addDashboardsToGroup = (groupId: string, dashboardIds: Array<string>) => this.httpPatchRequest(`${groupId}/dashboards/add?${this.generateQueryString({ d: dashboardIds })}`);

    /**
     * Removes dashboard links from a group
     * @param {string} groupId - Group ID
     * @param {Array<string>} dashboardIds - Array of dashboard IDs to remove
     */
    removeDashboardsFromGroup = (groupId: string, dashboardIds: Array<string>) => this.httpPatchRequest(`${groupId}/dashboards/remove?${this.generateQueryString({ d: dashboardIds })}`);
}
