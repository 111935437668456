import BaseService from './baseService';

export default class DashboardTableService extends BaseService {
    constructor() {
        super('dashboards-table');
    }

    /**
     * Fetches a dashboard query(named Tables on UI) by ID
     * @param {string} tableId - GUID of the dashboard query
     * @returns Dashboard query view item
     */
    getDashboardTable = (tableId: string) => this.httpGetRequest(`${tableId}`);


    /**
     * Fetches the list of dashboard queries(named Tables on UI)
     * @param {Object} filters - Filters for dashboard groups
     * @param {Array<string>} [filters.tableIds] - Array of query IDs
     * @param {Array<string>} [filters.tableName] - Array of table names
     * @param {Array<string>} [filters.dashboardIds] - Array of dashboard IDs
     * @param {boolean} [filters.isActive] - Filter by active status
     * @param {Array<number>} [filters.releaseGroupIds] - Array of release group IDs
     * @param {number} [filters.pageNumber] - Page number for pagination
     * @param {number} [filters.pageSize] - Page size for pagination
     * @param {string} [filters.searchString] - Page size for pagination
     * @returns Paginated list of dashboard groups
     */
    getDashboardTableList = (filters: Partial<{
        tableIds: Array<string>;
        tableName: Array<string>;
        dashboardIds: Array<string>;
        isActive: boolean;
        releaseGroupIds: Array<number>;
        pageNumber: number;
        pageSize: number;
        searchString: string;
    }> = {}) => {
        const queryString = this.generateQueryString({
            q: filters.tableIds,
            n: filters.tableName,
            d: filters.dashboardIds,
            a: filters.isActive,
            r: filters.releaseGroupIds,
            PageNumber: filters.pageNumber,
            PageSize: filters.pageSize,
            SearchString: filters.searchString,
        });
        return this.httpGetRequest(`list?${queryString}`);
    };

    /**
     * Creates a new dashboard table
     * @param {Object} dashboardTable - Data for the new dashboard group
     * @param {number} [dashboardTable.releaseGroupId] - Release group ID
     * @param {string} [dashboardTable.tableName] - Table name
     * @param {string} [dashboardTable.description] - Table description
     * @param {string} [dashboardTable.sqlQuery] - Tabele sqlQuery
     * @returns GUID of the created dashboard query
     */
    createDashboardTable = (dashboardTable: Partial<{
        releaseGroupId: number;
        tableName: string;
        description: string;
        sqlQuery: string;
    }>) => this.httpPostRequest('', dashboardTable);

    /**
     * Updates an existing dashboard table
     * @param {Object} dashboardTable - Data for the new dashboard query
     * @param {string} [dashboardTable.tableName] - Query name
     * @param {string} [dashboardTable.description] - Query description
     * @param {string} [dashboardTable.tableId] - Query ID
     * @param {string} [dashboardTable.sqlQuery] - SQL query
     * @returns GUID of the updated dashboard query
     */
    updateDashboardTable = (dashboardTable: Partial<{
        tableName: string;
        description: string;
        tableId: string;
        sqlQuery: string;
    }>) => this.httpPutRequest('', dashboardTable);
}
