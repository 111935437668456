import React, { Fragment } from 'react';
import {
    CardBody,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledDropdown,
    DropdownMenu,
    NavLink,
    NavItem,
    DropdownItem,
    Nav,
    DropdownToggle,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    faAngleDoubleLeft,
    faChevronLeft,
    faAngleDoubleRight,
    faChevronRight,
    faSortUp,
    faSortDown,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlockUi from 'react-block-ui';
import { ScaleLoader as Loader } from "react-spinners";
import cx from 'classnames';
import { sortingDirection, gridParams } from "common/enums";
import { redirectWithSearchParams } from "common/helpers";
import SearchableSelect from 'components/common/SearchableSelect';

export const PagingComponent = ({ pageSize, pageNumber = 1, total, changePage }) => {
    const maxPage = total > 0 && pageSize > 0 ? Math.ceil(total / pageSize) : 0;

    const pagesLeft = maxPage - pageNumber - 2;
    const pageFrom = Math.max(pageNumber - 2 - (pagesLeft < 0 ? -pagesLeft : 0), 1);

    let pages = [];
    let counter = pageFrom;
    while (pages.length < 5) {
        if (counter > maxPage) {
            break;
        }

        pages.push(counter);
        counter++;
    }

    const onChangePage = e => {
        typeof changePage === 'function' && changePage(e);
    }

    return (
        <div className="d-flex align-items-center justify-content-center flex-wrap">
            <Pagination className="pagination-primary">
                <PaginationItem disabled={pageNumber === 1}>
                    <PaginationLink first href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: 1 }); }}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageNumber === 1}>
                    <PaginationLink previous href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: pageNumber - 1 }); }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </PaginationLink>
                </PaginationItem>
                {pages?.map(p => <PaginationItem key={p} active={p === pageNumber}><PaginationLink href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: p }) }}>
                    {p}
                </PaginationLink></PaginationItem>)}
                <PaginationItem disabled={pageNumber === maxPage || maxPage === 0}>
                    <PaginationLink next href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: pageNumber + 1 }); }}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageNumber === maxPage || maxPage === 0}>
                    <PaginationLink last href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: maxPage }) }}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
            <span className="mx-2">{Math.max((pageNumber - 1) * pageSize, 1)} - {Math.min(total, pageNumber * pageSize)} of {total}</span>
        </div >)
}

export const SortableHeaderItem = ({ label, fieldName, currentSortColumn, sortDirection, onSort }) => {
    const onSortClick = e => {
        e.preventDefault();

        if (fieldName == currentSortColumn) {
            onSort({ sort: sortingDirection.asc == sortDirection ? sortingDirection.desc : sortingDirection.asc, fieldName })
        } else {
            onSort({ sort: sortingDirection.desc, fieldName });
        }
    }

    return (<a href="#" className="align-box text-gray" onClick={onSortClick}>
        <span className="mr-2"> {label}</span>
        {currentSortColumn === fieldName && <FontAwesomeIcon icon={sortDirection == sortingDirection.asc ? faSortUp : faSortDown} size="lg" />}
    </a>)
}

export const FilterableHeaderItem = ({ label, filterList, filterState, onFilter }) => {
    const onSelect = (value, sourceFilterParameter) => {
        onFilter({ value, filterParameter: sourceFilterParameter })
    }

    return (
        <UncontrolledDropdown className="px-2" style={{ cursor: 'pointer' }}>
            <DropdownToggle tag="div" color="light" className="truncated-line mw-100">
                {label}
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-xl p-2" style={{ height: '340px', minWidth: '300px' }}>
                <SearchableSelect
                    label="Athena Report Status Filter"
                    value={filterState[0]}
                    onChange={(value) => onSelect(value, gridParams.loadReportStates)}
                    source={filterList[0]?.map(s => ({ value: s.key, label: s.displayName }))}
                />
                <SearchableSelect
                    label="Dashbord Status Filter"
                    value={filterState[1]}
                    onChange={(value) => onSelect(value, gridParams.dashboardLoadStatuses)}
                    source={filterList[1]?.map(s => ({ value: s.key, label: s.displayName }))}
                />
                 <SearchableSelect
                    label="Snowflake Report Status Filter"
                    value={filterState[2]}
                    onChange={(value) => onSelect(value, gridParams.snowflakeRunStatuses)}
                    source={filterList[2]?.map(s => ({ value: s.key, label: s.displayName }))}
                />
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export const Footer = ({ filter, total, changePage, className, changePageSize, selectedIds }) => {
    const { pageSize, pageNumber = 1 } = filter || {};
    const maxPage = total > 0 && pageSize > 0 ? Math.ceil(total / pageSize) : 0;
    const pageSizeFilter = [{ key: 30, displayName: 30 }, { key: 60, displayName: 60 }, { key: 120, displayName: 120 }];

    const pagesLeft = maxPage - pageNumber - 2;
    const pageFrom = Math.max(pageNumber - 2 - (pagesLeft < 0 ? -pagesLeft : 0), 1);

    let pages = [];
    let counter = pageFrom;
    while (pages.length < 5) {
        if (counter > maxPage) {
            break;
        }

        pages.push(counter);
        counter++;
    }

    const onChangePage = e => {
        typeof changePage === 'function' && changePage(e);
    }

    return (<Fragment>
        {(selectedIds && selectedIds.length > 1) && <div>{selectedIds.length}</div>}
        <div className={cx("ml-auto pagination-wrapper", className)}>
            <Pagination className="pagination-primary">
                <PaginationItem disabled={pageNumber === 1}>
                    <PaginationLink first href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: 1 }); }}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageNumber === 1}>
                    <PaginationLink previous href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: pageNumber - 1 }); }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </PaginationLink>
                </PaginationItem>
                {pages?.map(p => <PaginationItem key={p} active={p === pageNumber}><PaginationLink href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: p }) }}>
                    {p}
                </PaginationLink></PaginationItem>)}
                <PaginationItem disabled={pageNumber === maxPage || maxPage === 0}>
                    <PaginationLink next href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: pageNumber + 1 }); }}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={pageNumber === maxPage || maxPage === 0}>
                    <PaginationLink last href="#" onClick={e => { e.preventDefault(); onChangePage({ idx: maxPage }) }}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
        <div className='ml-auto'>
            <UncontrolledDropdown className={cx(["px-2", { 'invisible': !changePageSize }])}>
                <DropdownToggle color="neutral-primary" size="sm" className="no-caret">
                    <span className="btn-wrapper--label">{pageSize}</span>
                    <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={faChevronDown} className="opacity-8 font-size-xs ml-1" />
                    </span>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-sm">
                    <Nav pills className="nav-neutral-dark flex-column">
                        {pageSizeFilter.map((o, k) => (
                            <DropdownItem key={k} className='m-0 p-0'>
                                <NavItem onClick={e => { e.preventDefault(); changePageSize(o.key) }}>
                                    <NavLink><small>{o.displayName}</small></NavLink>
                                </NavItem>
                            </DropdownItem>
                        ))}
                    </Nav>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    </Fragment>)
}

const DefaultHeader = ({ headerText }) => (<b>{headerText}</b>)

export const makeGridView = (GridItem, TableHeader, TableActionsMenu, GridHeader = DefaultHeader, GridSubHeader = null, pageName = '') => {
    return class GridComponent extends React.Component {
        constructor(props) {
            super(props);
        }

        changePage = e => {
            redirectWithSearchParams([
                { [gridParams.pageFilter]: pageName },
                { [gridParams.pageNumber]: e.idx }
            ]);

            const { applyFilters, changeFilter } = this.props.actions;
            typeof changeFilter === 'function' && changeFilter({ pageNumber: e.idx })
            typeof applyFilters === 'function' && applyFilters();
        }

        onChangePageSize = pageSize => {
            redirectWithSearchParams([
                { [gridParams.pageFilter]: pageName },
                { [gridParams.pageNumber]: 1 },
                { [gridParams.pageSize]: pageSize }
            ]);

            const { applyFilters, changeFilter } = this.props.actions;
            typeof changeFilter === 'function' && changeFilter({ pageSize, pageNumber: 1 })
            typeof applyFilters === 'function' && applyFilters();
        }

        render() {
            const { actions, additionalSource, showSpinner, source, pageable, filter, total, size, selectedIds } = this.props;


            return (<Fragment>
                <BlockUi tag="div" className={cx("card card-box", { "container-height-sm": size == "sm", "container-height": size != "sm" })} blocking={showSpinner} loader={<Loader color={'var(--first)'} loading={showSpinner} />}>
                    <div className="card-header flex-column align-items-stretch">
                        <div className='d-flex'>
                            <div className="card-header--title">
                                <GridHeader {...this.props} />
                            </div>
                            <div className="card-header--actions">
                                <TableActionsMenu {...this.props} />
                            </div>
                        </div>
                        {typeof GridSubHeader === "function" &&
                            <div className='d-flex'>
                                <GridSubHeader {...this.props} />
                            </div>}
                    </div>
                    <CardBody className="p-0">
                        <PerfectScrollbar option={{ wheelPropagation: false }}>
                            <div>
                                <Table className={cx({ "invisible": showSpinner })} borderless hover striped className="mb-0" size="sm">
                                    <TableHeader {...this.props} />
                                    <tbody className='grid-body'>
                                        {source && source.length > 0 && source.map(i => (<GridItem key={i.key} item={i} actions={actions} additionalSource={additionalSource} />))}
                                    </tbody>
                                </Table>
                            </div>
                        </PerfectScrollbar>
                    </CardBody>
                    <div className="divider" />
                    <div className="divider" />
                    <div className="p-3 d-flex">
                        <Footer
                            className={cx({ "invisible": !pageable })}
                            filter={filter}
                            total={total}
                            changePage={this.changePage}
                            changePageSize={this.onChangePageSize}
                            selectedIds={selectedIds}
                        />
                    </div>
                </BlockUi>
            </Fragment >)
        }
    }
}
