import React, { Fragment, Component, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import * as layoutActions from 'actions/layoutActions';
import { changePassword } from 'actions/accountManagerActions';
import BlockUi from 'react-block-ui';
import { ScaleLoader } from "react-spinners";

const ReportingRoot = lazy(() => import("containers/reporting/RootComponent"));
const ProjectManagement = lazy(() => import("containers/ProjectManagement"));
const TaskManagement = lazy(() => import("containers/TaskManagement"));
const ScenarioStatus = lazy(() => import("containers/ScenarioStatus"));
const TaskRoot = lazy(() => import('containers/task/RootComponent'));
const Documentation = lazy(() => import('containers/documentation/Documentation'));
const AdminManagement = lazy(() => import('containers/adminManagement/RootComponent'));

import PrivateRoute from "components/common/PrivateRoute";
import { ErrorModal, Header, SettingsEdit, EventsListModal } from 'components/layout'
import { ChangePasswordModal } from "components/accountManager";
import PageNotFound from "components/errors/PageNotFound";
import Forbidden from "components/errors/Forbidden";
import { parseNotifications } from "common/helpers";
import { NavLink as RRNavLink, Switch } from "react-router-dom";
import { NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import cx from 'classnames';

class LayoutContainer extends Component {
    componentDidMount() {
        const {
            fetchUser,
            setLogUrl,
            logo,
            setCurrentUser,
            toggleGeneralSpinner,
            getFeaturesConfiguration,
            setFeaturesConfiguration,
        } = this.props;

        getFeaturesConfiguration({
            successCallback: configuration => {
                setLogUrl(logo);
                setFeaturesConfiguration(configuration);
                fetchUser({ spinnerToggle: toggleGeneralSpinner, successCallback: setCurrentUser });
            }
        })
    }

    constructor(props) {
        super(props);

        this.settingsEdit = React.createRef();
        this.changePasswordModal = React.createRef();
        this.eventListModal = React.createRef();
    }

    openSettingsEdit = () => {
        const { getSettings } = this.props;
        getSettings({
            successCallback: response => {
                this.settingsEdit.current.open({ maxStaticReportMachines: response.maxStaticReportMachines });
            },
        });
    }

    onSaveSettings = e => {
        const { modifySettings } = this.props;
        this.settingsEdit.current.lock();
        modifySettings({
            settings: e,
            validationCallback: errors => {
                this.settingsEdit.current.unlock();
                this.settingsEdit.current.addNotification({ errors });
            },
            successCallback: () => {
                this.settingsEdit.current.unlock();
                this.settingsEdit.current.close();
            },
        });
    }

    changePassword = () => {
        const { currentUser } = this.props;

        this.changePasswordModal.current.open({ userId: currentUser.userId, currentUser });
    };

    onChangePasswordSubmit = request => {
        const { changePassword, toggleGeneralSpinner } = this.props;

        this.changePasswordModal.current.lock();
        changePassword({
            request,
            spinnerToggle: toggleGeneralSpinner,
            successCallback: () => {
                this.changePasswordModal.current.unlock();
                this.changePasswordModal.current.close();
                this.signOut();
            },
            validationCallback: errors => {
                this.changePasswordModal.current.unlock();
                this.changePasswordModal.current.addNotification({ errors });
            }
        })
    };

    onCleanStatuses = () => this.props.cleanStatuses();

    onDismissStatus = procGuid => this.props.dismissStatus(procGuid);

    onSearch = value => {
        const { fetchScenario, toggleLayoutSpinner } = this.props;

        if (!value.match(/^\d+$/)) {
            parseNotifications({ errors: ['Invalid case ID specified.'] })
        }

        fetchScenario({
            scenarioId: value,
            spinnerToggle: toggleLayoutSpinner,
            successCallback: response => {
                if (response.result === null) {
                    parseNotifications({ errors: [`Case "${value}" not found.`] })
                } else {
                    window.location = `/status/${value}`
                }
            },
        });
    }

    render() {

        const {
            isConfirmationOpen,
            onConfirmationSuccess,
            onConfirmationReject,
            confirmationMessage,
            generalSpinner,
            dashboardStatuses,
            jobStatuses,
            currentUser,
            navigation,
            featuresConfiguration,
            signOut,
            sidebarMenu,
            fetchFeedData,
            setNewPostAvailable,
            newPostAvailable
        } = this.props;

        const events = dashboardStatuses.concat(jobStatuses);
        const sidebarVisible = sidebarMenu.length > 0;

        return (
            <Fragment>
                <BlockUi tag="div" blocking={generalSpinner}
                    loader={<ScaleLoader color={'var(--first)'} loading={generalSpinner} />}>
                    <div className={cx("app-wrapper  vh-100 bg-gray-100", { 'app-sidebar-fixed app-header-fixed app-sidebar-collapsed': sidebarVisible })} >
                        {sidebarVisible &&
                            <div className="app-sidebar app-sidebar--mini app-sidebar--dark" style={{ zIndex: 999 }}>
                                <div className="app-sidebar--header">

                                </div>
                                <div className="app-sidebar--content">
                                    <div className="sidebar-menu-collapsed py-2">
                                        {sidebarMenu.map(n => (
                                            <NavItem key={n.idx} id={`nav-menu-item-${n.idx}`}>
                                                <NavLink {...(n.onClick
                                                    ? { onClick: n.onClick, className: 'cursor-pointer' }
                                                    : { tag: RRNavLink, to: n.link })}
                                                >
                                                    <div className="nav-link-icon">
                                                        {n.icon.prefix ? <FontAwesomeIcon icon={n.icon} size="lg" /> : n.icon}
                                                    </div>
                                                </NavLink>
                                                <UncontrolledTooltip popperClassName="tooltip-secondary" placement="right" container="body" boundariesElement="window" target={`nav-menu-item-${n.idx}`}>{n.label}</UncontrolledTooltip>
                                            </NavItem>))
                                        }
                                    </div>
                                </div>
                                <div className="app-sidebar--footer">

                                </div>
                            </div>}
                        <div className="app-main">
                            <Header
                                actions={{
                                    editSettings: this.openSettingsEdit,
                                    signOut: signOut,
                                    changePassword: this.changePassword,
                                    search: this.onSearch,
                                    fetchFeedData: fetchFeedData,
                                    setNewPostAvailable: setNewPostAvailable,
                                }}
                                references={{ eventsList: this.eventListModal }}
                                data={{ currentUser, eventsCount: events.length, featuresConfiguration, newPostAvailable }}
                                navigation={navigation} />
                            <div className="app-content">
                                <div className="app-inner-content-layout--main">
                                    <div className={cx("app-inner-content-layout", { "app-inner-content-layout-fixed": sidebarVisible })}>
                                        <div className="app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary p-3 mh-100 mw-100">
                                            <Switch>
                                                <PrivateRoute exact path="/" component={ProjectManagement} />
                                                <PrivateRoute path="/reporting/:taskId" component={ReportingRoot} />
                                                <PrivateRoute path="/projects" component={ProjectManagement} />
                                                <PrivateRoute path="/project/:projectId" component={TaskManagement} />
                                                <PrivateRoute path="/task/:taskId" component={TaskRoot} />
                                                <PrivateRoute path="/status/:scenarioId" component={ScenarioStatus} />
                                                <PrivateRoute path="/notAuthorized" component={PageNotFound} />
                                                <PrivateRoute path='/forbidden' component={Forbidden} />
                                                <PrivateRoute path='/documentation' component={Documentation} />
                                                <PrivateRoute path='/management' component={AdminManagement} />
                                                <PrivateRoute component={PageNotFound} />
                                            </Switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <EventsListModal
                        events={events}
                        ref={this.eventListModal}

                        actions={{
                            clean: this.onCleanStatuses,
                            dismiss: this.onDismissStatus
                        }} />

                    <ConfirmationDialog
                        isOpen={isConfirmationOpen}
                        successCallback={onConfirmationSuccess}
                        rejectCallback={onConfirmationReject}
                        message={confirmationMessage} />

                    <ChangePasswordModal
                        ref={this.changePasswordModal}
                        header="Change password"
                        onSubmit={this.onChangePasswordSubmit} />

                    <ErrorModal />
                </BlockUi>
            </Fragment>
        );
    }
};

LayoutContainer.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = state => ({
    ...state.layout,
    featuresConfiguration: state.layout.featuresConfiguration,
    isConfirmationOpen: state.layout.showConfirmation
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...layoutActions, changePassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
