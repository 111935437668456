import types from "common/actionTypes";
import { fetch } from "common/helpers";
import {
    lookupSvc,
    staticReportsAthenaSvc as reportsSvc,
    dashboardMgmtService as dashboardSvc
} from "services/serviceStorage";
import moment from "moment";
import { databaseTableType } from 'common/enums';

// tab spinners
export const toggleMasterSpinner = showSpinner => ({ type: types.SR_TOGGLE_MASTER_SPINNER, data: { showSpinner } });
export const toggleResultSpinner = (tabId, isLoading) => ({ type: types.SR_TOGGLE_RESULT_SPINNER, payload: { tabId, isLoading } });

// exports
export const changeMasterLevel = level => ({ type: types.SR_SET_MASTER_LEVEL, data: { level } });

export const setLookups = lookups => ({ type: types.SR_SET_LOOKUPS, data: lookups });

export const setReportingSettings = settings => ({ type: types.SR_SET_SETTINGS, data: settings });

export const setCases = cases => ({ type: types.SR_SET_CASES, data: cases });
export const setCasesSelected = selectedIds => ({ type: types.SR_SET_CASES_SELECTED, data: { selectedIds } });
export const setCasesFilter = data => ({ type: types.SR_SET_CASES_FILTER, data });
export const setTables = (tables) => {
    const tablesTypeBase = [];
    const tablesTypeView = [];
    const tablesTypeDashboard = [];

    tables.forEach(table => {
        switch (table.type) {
            case databaseTableType.baseTable:
                tablesTypeBase.push(table);
                break;
            case databaseTableType.view:
                tablesTypeView.push(table);
                break;
            case databaseTableType.dashboard:
                tablesTypeDashboard.push(table);
                break;
            default:
                break;
        }
    });

    return {
        type: types.SR_SET_TABLES,
        data: {
            tablesTypeBase,
            tablesTypeView,
            tablesTypeDashboard
        }
    };
};

export const setStaticReports = reports => ({ type: types.SR_SET_STATIC_REPORTS, data: reports });
export const setTemplates = temlate => ({ type: types.SR_SET_TEMPLATE, data: temlate });
export const changeTemplateSelection = selectedIds => ({ type: types.SR_SET_TEMPLATE_SELECTED, data: { selectedIds } });
export const changeReportSelection = selectedIds => ({ type: types.SR_SET_REPORT_SELECTED, data: { selectedIds } });

export const resetEditorTab = () => ({ type: types.SR_RESET_EDITOR_TAB });
export const reset = () => ({ type: types.SR_RESET });

export const fetchReportingLookup = ({ taskId, spinnerToggle, callback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => lookupSvc.getReportingLookup(taskId),
    });

// request static reports source
export const fetchStaticReportsSource = ({ filter, spinnerToggle, successCallback }) =>
    fetch({
        spinnerToggle,
        callback: r =>
            successCallback({
                data: r.result.items.map(i => ({
                    description: i.description,
                    reportName: i.staticReportName,
                    idx: i.staticReportId,
                    templates: i.templates,
                })),
                total: r.result.totalCount,
            }),
        request: () => reportsSvc.getStaticReports(filter),
    });

// request cases source
export const fetchCasesSource = ({ filter, spinnerToggle, successCallback }) =>
    fetch({
        spinnerToggle,
        callback: r =>
            successCallback({
                data: r.result.items.map(i => ({ ...i, idx: i.scenarioId })),
                total: r.result.totalCount,
            }),
        request: () => reportsSvc.getScenarios(filter),
    });

// request status source
export const fetchTemplatesSource = ({ filter, spinnerToggle, successCallback }) =>
    fetch({
        spinnerToggle,
        callback: r =>
            successCallback({
                data: r.result.items.map(i => ({ ...i, idx: i.templateId })),
                total: r.result.totalCount,
            }),
        request: () => reportsSvc.getTemplates(filter),
    });

// request status source
export const saveStaticReport = ({ report, callback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => reportsSvc.saveStaticReport(report),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const saveReportTemplate = ({ template, callback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => reportsSvc.saveTemplate({ template }),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const getStaticReport = ({ idx, callback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => reportsSvc.getStaticReport(idx),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });


export const getTemplate = ({ templateId, spinnerToggle, callback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => reportsSvc.getTemplate(templateId),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const deleteStaticReports = ({ reportIds, spinnerToggle, callback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => reportsSvc.deleteStaticReports(reportIds),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const deleteTemplates = ({ templateIds, spinnerToggle, callback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        request: () => reportsSvc.deleteTemplates({ templateIds }),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const downloadResults = ({ queryExecutionId, callback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r => callback(r.result),
        errorOnWarning: true,
        request: () => reportsSvc.downloadResults(queryExecutionId),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const loadResults = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        request: () => reportsSvc.loadResults(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const getTables = ({ databaseName, spinnerToggle, successCallback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        request: () => reportsSvc.getTables(databaseName),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const setActiveTab = (tabId) => ({ type: types.SR_SET_ACTIVE_TAB, payload: tabId });

export const addTab = (tab) => ({ type: types.SR_ADD_NEW_TAB, payload: tab });

export const closeTab = (tabId) => ({ type: types.SR_CLOSE_TAB, payload: tabId });

export const changeTabContents = (tabId, query) => ({ type: types.SR_CHANGE_TAB_CONTENTS, payload: { tabId, query } });

export const runQuery = ({ request, taskId, successCallback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r =>
            successCallback({
                queryExecutionId: r.result.queryExecutionId,
                nextToken: r.result.nextToken,
                totalCount: r.result.totalCount,
                queryResult: r.result.queryResult,
            }),
        request: () => reportsSvc.runQuery(request, taskId),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const setResultData = (tabId, data) => ({ type: types.SR_SET_RESULT_DATA, payload: { tabId, data } });

export const setGridColumns = (tabId, data) => ({ type: types.SR_SET_GRID_COLUMNS, payload: { tabId, data } });

export const setGridRows = (tabId, data) => ({ type: types.SR_SET_GRID_ROWS, payload: { tabId, data } });

export const getAthenaQueriesHistories = ({ filter, successCallback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: r =>
            successCallback({
                data: r.result.items.map(i => ({ ...i, startTime: moment(i.startTime).format('M.D.YYYY h:mm A'), durationMs: moment.duration(i.durationMs).format("mm:ss", { trim: false }) })),
                total: r.result.totalCount,
            }),
        request: () => reportsSvc.getAthenaQueriesHistories(filter),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const setAthenaHistories = source => ({ type: types.SR_SET_ATHENA_HISTORIES, data: source });

export const setAthenaFilter = data => ({ type: types.SR_SET_ATHENA_FILTER, data });

export const fetchRunReports = ({ request, successCallback, spinnerToggle, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        callback: successCallback,
        request: () => reportsSvc.runReportsForScenarios(request),
        errorCallback: errorCallback,
        validationCallback: validationCallback,
    });

export const openEditModal = (title, item = {}, templates = [], activeTabQuery = "") => ({
    type: types.SR_OPEN_EDIT_MODAL,
    data: {
        title: title,
        item: {
            staticReportName: item.staticReportName || '',
            description: item.description || '',
            query: item.query || '',
            templateIds: item.templateIds || [],
            staticReportId: item.staticReportId || null,
            templates: item.templates || null,
            global: item.taskId === null, // taskId is null if it's global
        },
        templates,
        activeTabQuery,
    },
});

export const closeEditModal = () => ({
    type: types.SR_CLOSE_EDIT_MODAL,
});

export const changeEditModalFields = (data) => ({
    type: types.SR_EDIT_MODAL_CHANGE_FIELDS,
    data: data,
});

export const setTabs = (tabs) => ({
    type: types.SR_SET_TABS,
    payload: tabs
});

export const setEndQuerys = (endQuerys) => ({
    type: types.SR_SET_END_QUERYS,
    payload: endQuerys
});

export const resetResultData = (tabId) => ({
    type: types.SR_RESET_RESULT_DATA,
    payload: tabId
});

//Task 3589 on-hold
export const openDashboardModal = () => ({ type: types.SR_OPEN_DASHBOARD_MODAL });

export const closeDashboardtModal = () => ({ type: types.SR_CLOSE_DASHBOARD_MODAL });

export const getHeaders = ({ files, spinnerToggle, successCallback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        request: () => dashboardSvc.getHeaders(files),
        callback: r => successCallback({ columns: r.result.map(column => ({ name: column, dataType: 'STRING' })) }),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const changeDashboardModalFields = (data) => ({ type: types.SR_DASHBOARD_MODAL_CHANGE_FIELDS, data: data });

export const saveDashboardTable = ({ formData, spinnerToggle, successCallback, errorCallback, validationCallback }) =>
    fetch({
        spinnerToggle,
        request: () => dashboardSvc.uploadDataFile(formData),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const resetDashboardModal = () => ({ type: types.SR_RESET_DASHBOARD_MODAL });
//