import BaseService from './baseService';

export default class SnowflakeService extends BaseService {
    constructor() {
        super('snowflake-integration');
    }

    /**
     * Generates the initial Snowflake db infrastructure for the release.
     * @param {number} releaseGroupId - Release group id
     */
    generateInitialDb = (releaseGroupId: number) => this.httpPostRequest(`infrastructure/generate/${releaseGroupId}`);

    /**
    * Loads the files for the scenario to Snowflake.
    * @param {Array<number>} scenarioIds - ScenarioIds
    */
    loadScenarioFiles = (scenarioIds: number[]) => this.httpPostRequest('raw/load', { scenarioIds });

    /**
    * Gets snowflake configuration.
    * @returns Snowflake account id
    */
    configuration = () => this.httpGetRequest('configuration');
}