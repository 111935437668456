import React, { Fragment, Component } from 'react';
import {
    Button,
    ListGroup,
    Card,
    ListGroupItem,
    CardBody,
    Modal,
    Row,
    NavLink,
    Col,
    Badge
} from "reactstrap";
import Scrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import cx from "classnames"
import { history } from "store";
import { scenarioReportQueueType, uiPages, notificationStatus } from "common/enums";

const notificationType = {
    dashboardLoading: 1,
    runningJobs: 2,
    copyFiles: 3,
    runningPSOJobs: 4,
    runningFunctions: 5,
    runningReports: 6,
    copyTask: 7,
    runningStaticReportQueries: 8,
    runnigPSOLogs: 9
}

const getRunningJobTitle = (runMode) => {
    switch (runMode) {
        case 'RunAll':
            return 'Running PSO'
        case 'LoadPartition':
            return 'Loading Cube'
        case 'DeletePartition':
            return 'Loading Cube'
        case 'BatchRead':
            return 'Running Validation'
        default:
            return `Running job '${runMode}'`
    }
}

const DashboardLoadItemTemplate = ({ status, progress, dashboard, processStatus }) => {
    return (
        <div className="d-flex flex-column">
            <b className="text-primary">{`Loading Dashboard ${dashboard}`}</b>
            <span className="mr-2">
                <b className="mr-auto">Status message: </b> {status || "N/A"}
            </span>
            <div className="d-block">
                <span className="mr-2">
                    <b className="mr-auto">% Complete: </b> {progress}
                </span>
            </div>
        </div>);
};

const RunningPSOJobsTemplate = props => {
    const {
        taskId,
        scenarioId,
        scenarioName,
        taskName,
        runMode,
        runStatus,
        projectId,
        projectName,
        close
    } = props;

    const redirect = () => {
        history.push(`/status/${scenarioId}`);

        close();
    };

    return (
        <div>
            <Row>
                <Col className="w-100 mb-1">
                    <b className="text-primary mr-auto">{getRunningJobTitle(runMode)}</b>
                </Col>
            </Row>
            <Row>
                <Col sm="5" className="d-flex flex-column">
                    <span>
                        <b className="mr-auto">Project: </b>
                        <span className="text-primary">#{projectId} {projectName}</span>
                    </span>
                    <span>
                        <b className="mr-auto">Task: </b>
                        <span className="text-primary">#{taskId} {taskName}</span>
                    </span>
                    {scenarioName && scenarioId && (
                        <span>
                            <b className="mr-auto"> Case: </b>
                            <NavLink to={`/status/${scenarioId}`} onClick={redirect} className="d-inline-block p-0">
                                <Button color="link" className="p-0"><span className="text-primary">#{scenarioId} {scenarioName}</span></Button>
                            </NavLink>
                        </span>
                    )}
                </Col>
                <Col sm="5" className="d-flex flex-column">
                    <span>
                        <b className="mr-auto">Run status : </b>
                        <span>{runStatus}</span>
                    </span>
                </Col>
            </Row>
        </div>
    );
};

const CopyFilesItemTemplate = props => {
    const { progress, requestName } = props;

    return (
        <Fragment>
            <div>
                <span className="text-primary mr-auto">{`Copying files. ${requestName}`}</span>
                <span className="ml-2">
                    <div className="d-block">
                        <span className="mr-2">
                            <b className="mr-auto">Complete: </b>{`${progress}%`}
                        </span>
                    </div>
                </span>
            </div>
        </Fragment>
    );
};

const CopyTaskItemTemplate = props => {
    const { progress, taskId, statusMessage } = props;

    return (
        <Fragment>
            <div>
                <span className="text-primary mr-auto">{`Copying task ${taskId}.`}</span>
                <span className="ml-2">
                    <div className="d-block">
                        <span className="mr-2">
                            <b className="mr-auto">Status message: </b> {statusMessage}
                        </span>
                    </div>
                    <div className="d-block">
                        <span className="mr-2">
                            <b className="mr-auto">Complete: </b>{`${progress * 100}%`}
                        </span>
                    </div>
                </span>
            </div>
        </Fragment>
    );
};


const RunningFunctionsTemplate = ({ status, functionName, type, names }) => {
    return (
        <div className="d-flex flex-column">
            <b className="text-primary">{`Running function '${functionName}'`}</b>
            <span className="mr-2">
                <b className="mr-auto">{type === uiPages.scenarioGroup ? "Case groups" : "Cases"}: </b>
                <span className="text-primary">{names?.join(', ')}</span>
            </span>
            <span className="mr-2">
                <b className="mr-auto">Status message: </b> {status || "N/A"}
            </span>
        </div>);
};

const RunningJobsTemplate = props => {
    const {
        taskId,
        scenarioId,
        scenarioName,
        taskName,
        runStatus,
        progress,
        startWork,
        statusMessage,
        segmentIdx,
        endWork,
        runMode,
        processStatus,
        projectId,
        projectName,
        close
    } = props;

    const redirect = () => {
        history.push(`/status/${scenarioId}`);

        close();
    };

    return (
        <div >
            <Row>
                <Col className="w-100 mb-1">
                    <b className="text-primary mr-auto">{getRunningJobTitle(runMode)}</b>
                </Col>
            </Row>
            <Row>
                <Col sm="5" className="d-flex flex-column">
                    <span>
                        <b className="mr-auto">Project: </b>
                        <span className="text-primary">#{projectId} {projectName}</span>
                    </span>
                    <span>
                        <b className="mr-auto">Task: </b>
                        <span className="text-primary">#{taskId} {taskName}</span>
                    </span>
                    {scenarioName && scenarioId && (
                        <span>
                            <b className="mr-auto"> Case: </b>
                            <NavLink to={`/status/${scenarioId}`} onClick={redirect} className="d-inline-block p-0">
                                <Button color="link" className="p-0"><span className="text-primary">#{scenarioId} {scenarioName}</span></Button>
                            </NavLink>
                        </span>
                    )}
                    {segmentIdx != null && (
                        <span>
                            <b className="mr-auto">Segment: </b>
                            <span className="text-primary">{segmentIdx}</span>
                        </span>
                    )}
                </Col>
                <Col sm="5" className="d-flex flex-column">
                    <span>
                        <b className="mr-auto">% Complete : </b>
                        <span>{progress}</span>
                    </span>
                    <span>
                        <b className="mr-auto">Run status : </b>
                        <span>{runStatus}</span>
                    </span>
                    {startWork && (
                        <span>
                            <b className="mr-auto">Start work : </b>
                            <span> {moment(startWork).format("MM/DD/YYYY HH:mm:ss")}</span>
                        </span>
                    )}
                    {endWork && (
                        <span>
                            <b className="mr-auto">End work : </b>
                            <span>{moment(endWork).format("MM/DD/YYYY HH:mm:ss")}</span>
                        </span>
                    )}
                </Col>
            </Row>
            {statusMessage && <Row className="mt-2">
                <Col>
                    <b>Status message : </b> <span className={cx({ "text-danger": processStatus === notificationStatus.error })}>{statusMessage}</span>
                </Col>
            </Row>}
        </div>
    );
};

const RunningReportsTemplate = ({ status, athenaDbName, type, projectId, projectName, scenarioNames }) => {
    return (
        <div className="d-flex flex-column">
            <b className="text-primary">{type === scenarioReportQueueType.load ? `Loading cases into '${athenaDbName}'` : `Unloading cases from '${athenaDbName}'`}</b>
            <span className="mr-2">
                <b className="mr-auto">Project: </b>
                <span className="text-primary">#{projectId} {projectName}</span>
            </span>
            <span className="mr-2">
                <b className="mr-auto">Cases: </b>
                <span className="text-primary">{scenarioNames?.join(', ')}</span>
            </span>
        </div>);
};


const RunningStaticReportTemplate = ({ requestName, progress, statusMessage }) => {

    return (
        <div className="d-flex flex-column">
            <b className="text-primary">{requestName}</b>
            <span className="mr-2">
                <b className="mr-auto">Progress: </b>
                <span className="text-primary">{`${progress * 100}%`}</span>
            </span>
            <span className="mr-2">
                <b className="mr-auto">Status message: </b> {statusMessage || "N/A"}
            </span>
        </div>);
};

const RunningPSOLogsTemplate = ({ requestName, progress, statusMessage }) => (
    <div className="d-flex flex-column">
        <b className="text-primary">{requestName}</b>
        <span className="mr-2">
            <b className="mr-auto">Progress: </b>
            <span className="text-primary">{`${progress * 100}%`}</span>
        </span>
        <span className="mr-2">
            <b className="mr-auto">Status message: </b> {statusMessage || "N/A"}
        </span>
    </div>
);


class ModalBody extends Component {
    state = { isOpen: false }
    templates = {
        [notificationType.dashboardLoading]: props => <DashboardLoadItemTemplate {...props} />,
        [notificationType.runningJobs]: props => <RunningJobsTemplate {...props} />,
        [notificationType.copyFiles]: props => <CopyFilesItemTemplate {...props} />,
        [notificationType.runningPSOJobs]: props => <RunningPSOJobsTemplate {...props} />,
        [notificationType.runningFunctions]: props => <RunningFunctionsTemplate {...props} />,
        [notificationType.runningReports]: props => <RunningReportsTemplate {...props} />,
        [notificationType.copyTask]: props => <CopyTaskItemTemplate {...props} />,
        [notificationType.runningStaticReportQueries]: props => <RunningStaticReportTemplate {...props} />,
        [notificationType.runnigPSOLogs]: props => <RunningPSOLogsTemplate {...props} />
    }

    statuses = {
        [notificationStatus.running]: ({ color: "info", text: "Running" }),
        [notificationStatus.completed]: ({ color: "success", text: "Completed" }),
        [notificationStatus.none]: ({ color: "secondary", text: "None" }),
        [notificationStatus.error]: ({ color: "danger", text: "Error" }),
    };

    constructor(props) {
        super(props);
    }

    resolveTemplate = ({ notificationType, payload = {}, ...rest }) => {
        const template = this.templates[notificationType];

        if (typeof template !== 'function') {
            return (<p>No template : {JSON.stringify({ ...payload, ...rest, notificationType })}</p>)
        }

        return template({ ...payload, ...rest, notificationType });
    };

    renderStatus = status => {
        const { color, text } = this.statuses[status] || {};
        return <Badge color={color}> {text || "Invalid status"} </Badge>
    }

    dismissStatus = processUid => {
        const { dismiss } = this.props.actions;
        typeof dismiss === "function" && dismiss(processUid)
    }

    open = () => this.setState({ isOpen: true });

    close = () => this.setState({ isOpen: false });

    render() {
        const { isOpen } = this.state;
        const { events = [] } = this.props;
        /* const events = [{
             endWork: null,
             eventTime: "2020-06-12T13:23:34.4959112Z",
             initiatorGuid: "2aa0ac01-ae4a-440f-9914-0c8786526b1a",
             initiatorName: "mike",
             notificationType: 4,
             processStatus: 2,
             processUid: "155719",
             progress: 0,
             projectId: 11152,
             projectName: "qweqwe",
             runBy: "2aa0ac01-ae4a-440f-9914-0c8786526b1a",
             runMode: "RunAll",
             runStatus: "Warning",
             scenarioId: 14672,
             scenarioName: "TestMaxSolve",
             segmentIdx: 0,
             startWork: "2019-08-12T16:55:07.957",
             statusMessage: "",
             taskId: 12493,
             taskName: "train-task"
         }, {
             dashboard: "HeatMap",
             eventTime: "2020-06-11T15:20:06.8569514Z",
             initiatorGuid: "00000000-0000-0000-0000-000000000000",
             initiatorName: "mike",
             notificationType: 1,
             processStatus: 1,
             processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
             progress: 50,
             scenarioId: 14538,
             scenarioName: "PI_load50_FCA12-Test5",
             status: "Loaded table Case_Info."
         },
             {
                 dashboard: "HeatMap",
                 eventTime: "2020-06-11T15:20:06.8569514Z",
                 initiatorGuid: "00000000-0000-0000-0000-000000000000",
                 initiatorName: "mike",
                 notificationType: 1,
                 processStatus: 1,
                 processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
                 progress: 50,
                 scenarioId: 14538,
                 scenarioName: "PI_load50_FCA12-Test5",
                 status: "Loaded table Case_Info."
             }, {
                 dashboard: "HeatMap",
                 eventTime: "2020-06-11T15:20:06.8569514Z",
                 initiatorGuid: "00000000-0000-0000-0000-000000000000",
                 initiatorName: "mike",
                 notificationType: 1,
                 processStatus: 1,
                 processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
                 progress: 50,
                 scenarioId: 14538,
                 scenarioName: "PI_load50_FCA12-Test5",
                 status: "Loaded table Case_Info."
             }, {
                 dashboard: "HeatMap",
                 eventTime: "2020-06-11T15:20:06.8569514Z",
                 initiatorGuid: "00000000-0000-0000-0000-000000000000",
                 initiatorName: "mike",
                 notificationType: 1,
                 processStatus: 1,
                 processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
                 progress: 50,
                 scenarioId: 14538,
                 scenarioName: "PI_load50_FCA12-Test5",
                 status: "Loaded table Case_Info."
             }, {
                 dashboard: "HeatMap",
                 eventTime: "2020-06-11T15:20:06.8569514Z",
                 initiatorGuid: "00000000-0000-0000-0000-000000000000",
                 initiatorName: "mike",
                 notificationType: 1,
                 processStatus: 1,
                 processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
                 progress: 50,
                 scenarioId: 14538,
                 scenarioName: "PI_load50_FCA12-Test5",
                 status: "Loaded table Case_Info."
             }, {
                 dashboard: "HeatMap",
                 eventTime: "2020-06-11T15:20:06.8569514Z",
                 initiatorGuid: "00000000-0000-0000-0000-000000000000",
                 initiatorName: "mike",
                 notificationType: 1,
                 processStatus: 1,
                 processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
                 progress: 50,
                 scenarioId: 14538,
                 scenarioName: "PI_load50_FCA12-Test5",
                 status: "Loaded table Case_Info."
             }, {
                 dashboard: "HeatMap",
                 eventTime: "2020-06-11T15:20:06.8569514Z",
                 initiatorGuid: "00000000-0000-0000-0000-000000000000",
                 initiatorName: "mike",
                 notificationType: 1,
                 processStatus: 1,
                 processUid: "a5988a85-cc61-4ac3-a94b-dd3a900b9222",
                 progress: 50,
                 scenarioId: 14538,
                 scenarioName: "PI_load50_FCA12-Test5",
                 status: "Loaded table Case_Info."
             }]; */

        return (<Fragment>
            <Modal isOpen={isOpen} toggle={this.close} centered size="xl">
                <Card className="card-box overflow-hidden">
                    <div className="card-header bg-light">
                        <div className="card-header--title">
                            <b>Events list</b>
                        </div>
                        <div className="card-header--actions">
                        </div>
                    </div>
                    {events.length > 0 &&
                        <Scrollbar style={{ maxHeight: "65vh" }}>
                            <ListGroup flush>
                                {events.map((event, i) => (<ListGroupItem key={i} className="w-100">
                                    <Row>
                                        <Col sm="8">{this.resolveTemplate({ ...event, close: this.close })}</Col>
                                        <Col sm="4">
                                            <div className="align-box-row">
                                                <div className="flex-column">
                                                    <span className="align-box-row">
                                                        <b className="mr-auto">Process status: </b>
                                                        <span className="ml-2">{this.renderStatus(event.processStatus)}</span>
                                                    </span>
                                                    <span className="align-box-row">
                                                        <b className="mr-auto">Latest update: </b>
                                                        <span className="ml-2">{moment(event.eventTime).format("MM/DD/YYYY HH:mm:ss")}</span>
                                                    </span>
                                                    <span className="align-box-row">
                                                        <b className="mr-auto">Run by: </b>
                                                        <span className="ml-2">{event.initiatorName}</span>
                                                    </span>
                                                </div>
                                                {notificationStatus.running !== event.processStatus &&
                                                    <div className="ml-auto mr-3">
                                                        <FontAwesomeIcon
                                                            style={{ cursor: "pointer" }}
                                                            icon={faTrashAlt}
                                                            onClick={() => this.dismissStatus(event.processUid)}
                                                            size="lg"
                                                        />
                                                    </div>}
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroupItem>))}
                            </ListGroup>
                        </Scrollbar>}
                    {events.length === 0 && <CardBody>
                        <p className="text-center">No events yet</p>
                    </CardBody>}
                    <div className="justify-content-end p-2">
                        <Button color="link" onClick={this.close}>Close</Button>
                    </div>
                </Card>
            </Modal>
        </Fragment>)
    }
}

export default ModalBody;
