import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { Middleware, Store } from 'redux';
import rootReducer from "../reducers/rootReducer";
import types from "../common/actionTypes";
import queryString from "query-string";
import { createBrowserHistory, History, LocationDescriptorObject, BrowserHistoryBuildOptions, LocationState } from "history";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import configuration from "../config";
import "babel-polyfill";
import { useDispatch, useSelector as reduxUseSelector, TypedUseSelectorHook } from "react-redux";
import { ParsedQuery } from 'query-string';

const connection = new HubConnectionBuilder()
    .withUrl(`${configuration.API_SERVER}/reportingHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorage.getItem("token") || "",
    })
    .build();

connection.serverTimeoutInMilliseconds = 120000;

const signalRInvokeMiddleware: Middleware = storeAPI => next => async action => {
    if (connection.state === "Disconnected") {
        await connection.start();
        await connection.invoke("Subscribe");
    }

    return next(action);
};

function signalRRegisterCommands(store: Store) {
    connection.on("Notify", (status) => {
        store.dispatch({
            type: types.HUB_DASHBOARD_LOAD_PROGRESS_UPDATED,
            data: { statuses: [status] },
        });
    });

    connection.on("NotifyJobs", (statusGroup) => {
        store.dispatch({ type: types.HUB_NOTIFICATION_JOBS, data: statusGroup });
    });

    connection.on("NotifyQueryExecution", (status) => {
        store.dispatch({ type: types.PSO_LOGS_EVENT_STATUS, data: status });
    });
}

function preserveQueryParameters(
    history: History,
    preserve: string[],
    location: LocationDescriptorObject
): LocationDescriptorObject {
    const currentQuery = queryString.parse(history.location.search) as ParsedQuery<string>;
    const preservedQuery: ParsedQuery<string> = {};

    if (currentQuery) {
        for (const p of preserve) {
            const v = currentQuery[p];
            if (v != null) {
                if (typeof v === 'string') {
                    preservedQuery[p] = v;
                } else if (Array.isArray(v)) {
                    const filteredArray = v.filter((item): item is string => item != null);
                    if (filteredArray.length > 0) {
                        preservedQuery[p] = filteredArray;
                    }
                }
            }
        }
        if (location.search) {
            const locationQuery = queryString.parse(location.search);
            Object.assign(preservedQuery, locationQuery);
        }
        location.search = queryString.stringify(preservedQuery);
    }
    return location;
}

function createLocationDescriptorObject(
    location: string | LocationDescriptorObject,
    state?: LocationState
): LocationDescriptorObject {
    if (typeof location === "string") {
        return {
            pathname: location,
            state,
            search: "",
            hash: "",
        };
    }
    return {
        ...location,
        state: state || location.state,
        search: location.search || "",
        hash: location.hash || "",
    };
}

function createPreserveQueryHistory(createHistory: (options?: BrowserHistoryBuildOptions) => History, queryParameters: string[]) {
    return (options?: BrowserHistoryBuildOptions) => {
        const history = createHistory(options);
        const oldPush = history.push, oldReplace = history.replace;

        history.push = (path: string | LocationDescriptorObject, state?: LocationState) => {
            const locationObj = createLocationDescriptorObject(path, state);
            const newLocation = preserveQueryParameters(history, queryParameters, locationObj);
            oldPush(newLocation);
        };

        history.replace = (path: string | LocationDescriptorObject, state?: LocationState) => {
            const locationObj = createLocationDescriptorObject(path, state);
            const newLocation = preserveQueryParameters(history, queryParameters, locationObj);
            oldReplace(newLocation);
        };

        return history;
    };
}

const history = createPreserveQueryHistory(createBrowserHistory, ["filter"])();

const middleware = [
    signalRInvokeMiddleware,
];

if (process.env.NODE_ENV !== "production") {
    middleware.push(createLogger({ collapsed: true }));
}

const store = configureStore({
    reducer: rootReducer(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: {
                isSerializable: () => true,
            },
            immutableCheck: true,
        }).concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
});

signalRRegisterCommands(store);

export { store, connection, history };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = reduxUseSelector;