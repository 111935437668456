var ActionTypes = {
    // signalr actions
    HUB_DASHBOARD_LOAD_PROGRESS_UPDATED: "HUB_DASHBOARD_LOAD_PROGRESS_UPDATED",
    HUB_NOTIFICATION_JOBS: "HUB_NOTIFICATION_JOBS",

    // layout actions
    LAYOUT_TOGGLE_GENERAL_SPINNER: "LAYOUT_TOGGLE_GENERAL_SPINNER",
    OPEN_CONFIRMATION: "OPEN_CONFIRMATION",
    CLOSE_CONFIRMATION: "CLOSE_CONFIRMATION",
    ON_GENERAL_INFO_LOAD: "ON_GENERAL_INFO_LOAD",
    ON_SCOPES_LOAD: "ON_SCOPES_LOAD",
    ON_LIBRARIES_LOAD: "ON_LIBRARIES_LOAD",
    LAYOUT_CLEAN_STATUSES: "LAYOUT/CLEAN STATUSES",
    LAYOUT_DISMISS_STATUS: "LAYOUT/DISMISS STATUS",
    LAYOUT_SET_USER: "LAYOUT/SET USER",
    LAYOUT_SET_LOGO_URL: "LAYOUT/SET LOGO",
    LAYOUT_SET_TITLE: "LAYOUT/SET TITLE",
    OPEN_ERROR_MODAL: "OPEN ERROR MODAL",
    CLOSE_ERROR_MODAL: "CLOSE ERROR MODAL",
    LAYOUT_SET_NAVIGATION: "LAYOUT/SET NAVIGATION",
    LAYOUT_SET_FEATURES: "LAYOUT/SET FEATURES",
    LAYOUT_SET_SIDEBAR_MENU: "LAYOUT/SET SIDEBAR MENU",
    LAYOUT_SET_NEW_POST_CMS: "LAYOUT_SET_NEW_POST_CMS",

    // PSO Results actions
    PSO_RESULTS_SET_MASTER_SELECTED: "PSO RESULTS/SET MASTER SELECTED",
    PSO_RESULTS_TOGGLE_MASTER_SPINNER: "PSO RESULTS/TOGGLE MASTER SPINNER",
    PSO_RESULTS_SET_MASTER_SOURCE: "PSO RESULTS/SET MASTER SOURCE",
    PSO_RESULTS_SET_DETAILS_SELECTED: "PSO RESULTS/SET DETAILS SELECTED",
    PSO_RESULTS_TOGGLE_DETAIL_SPINNER: "PSO RESULTS/TOGGLE DETAIL SPINNER",
    PSO_RESULTS_SET_DETAIL_SOURCE: "PSO RESULTS/SET DETAIL SOURCE",
    PSO_RESULTS_SET_BASE_INFO: "PSO_RESULTS_SET_BASE_INFO",
    PSO_RESULTS_SET_MASTER_FILTER: "PSO RESULTS/SET MASTER FILTER",
    PSO_RESULTS_SET_DETAIL_FILTER: "PSO RESULTS/SET DETAIL FILTER",

    // Dashboard actions
    DASHBOARD_SWITCH_TAB: "DASHBOARD_SWITCH_TAB",

    DASHBOARD_UPDATE_FILTER: "DASHBOARD_UPDATE_FILTER",
    DASHBOARD_SET_RESULT: "DASHBOARD_SET_RESULT",
    DASHBOARD_RESET_RESULT: "DASHBOARD_RESET_RESULT",
    DASHBOARD_SET_POINT_COLOR: "DASHBOARD_SET_POINT_COLOR",
    DASHBOARD_CHANGE_UNIT_TYPE_MODE: "DASHBOARD_CHANGE_UNIT_TYPE_MODE",
    DASHBOARD_TOGGLE_FILTERS_MODAL: "DASHBOARD_TOGGLE_FILTERS_MODAL",
    DASHBOARD_TOGGLE_AREAS_EDIT_MODAL: "DASHBOARD_TOGGLE_AREAS_EDIT_MODAL",

    DASHBOARD_UPDATE_MAP_FILTER: "DASHBOARD_UPDATE_MAP_FILTER",
    DASHBOARD_SET_MAP_RESULT: "DASHBOARD_SET_MAP_RESULT",
    DASHBOARD_RESET_MAP_RESULT: "DASHBOARD_RESET_MAP_RESULT",
    DASHBOARD_SET_MAP_COLORS: "DASHBOARD_SET_MAP_COLORS",
    DASHBOARD_SET_MAP_HOVERED_AREA: "DASHBOARD_SET_MAP_HOVERED_AREA",
    DASHBOARD_SET_MAP_HOVERED_INJECTOR: "DASHBOARD_SET_MAP_HOVERED_INJECTOR",
    DASHBOARD_SET_MAP_TOKEN: "DASHBOARD_SET_MAP_TOKEN",
    DASHBOARD_SET_MAP: "DASHBOARD_SET_MAP",
    DASHBOARD_SET_MAP_INJECTOR_FIELD: "DASHBOARD_SET_MAP_INJECTOR_FIELD",
    DASHBOARD_SET_MAP_ICONS: "DASHBOARD_SET_MAP_ICONS",
    DASHBOARD_SET_MAP_SHOW_AREAS: "DASHBOARD_SET_MAP_SHOW_AREAS",
    DASHBOARD_MAP_RESET_VIEW: "DASHBOARD_MAP_RESET_VIEW",
    DASHBOARD_SET_MAP_SELECTED_TAB: "DASHBOARD_SET_MAP_SELECTED_TAB",
    DASHBOARD_MAP_SET_TASK: "DASHBOARD_MAP_SET_TASK",
    DASHBOARD_SET_MAP_SELECTED_FIELD: "DASHBOARD_SET_MAP_SELECTED_FIELD",
    DASHBOARD_MAP_CHANGE_UNIT_TYPE_MODE: "DASHBOARD_MAP_CHANGE_UNIT_TYPE_MODE",
    DASHBOARD_MAP_TOGGLE_FILTERS_MODAL: "DASHBOARD_MAP_TOGGLE_FILTERS_MODAL",
    DASHBOARD_MAP_TOGGLE_AREAS_EDIT_MODAL: "DASHBOARD_MAP_TOGGLE_AREAS_EDIT_MODAL",

    DASHBOARD_COMPARER_SET_SCENARIOS: "DASHBOARD_COMPARER_SET_SCENARIOS",
    DASHBOARD_COMPARER_SET_MASTER_SCENARIO: "DASHBOARD_COMPARER_SET_MASTER_SCENARIO",
    DASHBOARD_COMPARER_SET_DETAIL_SCENARIO: "DASHBOARD_COMPARER_SET_DETAIL_SCENARIO",

    DASHBOARD_COMPARER_SET_FILES_SOURCE: "DASHBOARD_COMPARER_SET_FILES_SOURCE",
    DASHBOARD_COMPARER_SET_ROWS_SOURCE: "DASHBOARD_COMPARER_SET_ROWS_SOURCE",
    DASHBOARD_COMPARER_RESET_ROWS_SOURCE: "DASHBOARD_COMPARER_RESET_ROWS_SOURCE",
    DASHBOARD_COMPARER_SELECT_FILE: "DASHBOARD_COMPARER_SELECT_FILE",
    DASHBOARD_COMPARER_TOGGLE_FILES_SPINNER: "DASHBOARD_COMPARER_TOGGLE_FILES_SPINNER",
    DASHBOARD_COMPARER_TOGGLE_ROWS_SPINNER: "DASHBOARD_COMPARER_TOGGLE_ROWS_SPINNER",
    DASHBOARD_COMPARER_TOGGLE_RESULTS_SPINNER: "DASHBOARD_COMPARER_TOGGLE_RESULTS_SPINNER",
    DASHBOARD_COMPARER_RESET_SCREEN: "DASHBOARD_COMPARER_RESET_SCREEN",
    DASHBOARD_COMPARER_RESET_FILES_SOURCE: "DASHBOARD_COMPARER_RESET_FILES_SOURCE",
    DASHBOARD_COMPARER_RESET_FILE: "DASHBOARD_COMPARER_RESET_FILE",
    DASHBOARD_COMPARER_RESET_PAGING: "DASHBOARD_COMPARER_RESET_PAGING",

    PSO_LOGS_SET_SCENARIOS: "PSO_LOGS_SET_SCENARIOS",
    PSO_LOGS_SELECT_SCENARIOS: "PSO_LOGS_SELECT_SCENARIOS",
    PSO_LOGS_RESET: "PSO_LOGS_RESET",
    PSO_LOGS_SET_WARNINGS: "PSO_LOGS_SET_WARNINGS",
    PSO_LOGS_TOGGLE_DOWNLOAD_SPINNER: "PSO_LOGS_TOGGLE_DOWNLOAD_SPINNER",
    PSO_LOGS_TOGGLE_SCENARIOS_SPINNER: "PSO_LOGS_TOGGLE_SCENARIOS_SPINNER",
    PSO_LOGS_SELECT_LOG_LEVELS: "PSO_LOGS_SELECT_LOG_LEVELS",
    PSO_LOGS_SELECT_COMPARE_RULES: "PSO_LOGS_SELECT_COMPARE_RULES",
    PSO_LOGS_RESET_WARNINGS: "PSO_LOGS_RESET_WARNINGS",
    PSO_LOGS_EVENT_STATUS: "PSO_LOGS_EVENT_STATUS",

    //Instance Manager actions
    IM_SET_GRID_SOURCE: "IM/SET GRID SOURCE",
    IM_SET_GRID_VIEW: "IM/SET GRID VIEW",
    IM_TOGGLE_GRID_SPINNER: "IM/TOGGLE GRID SPINNER",
    IM_CLEAR_GRID_VIEW: "IM/CLEAR GRID VIEW",
    IM_CHANGE_FILTER_SEARCH_TERM: "IM/CHANGE FILTER SEARCH TERM",

    // Account Manager actions
    AM_SET_GRID_SOURCE: "AM/SET GRID SOURCE",
    AM_SET_GRID_VIEW: "AM/SET GRID VIEW",
    AM_TOGGLE_GRID_SPINNER: "AM/TOGGLE GRID SPINNER",
    AM_CLEAR_GRID_VIEW: "AM/CLEAR GRID VIEW",
    AM_CHANGE_FILTER_SEARCH_TERM: "AM/CHANGE FILTER SEARCH TERM",
    AM_OPEN_EDIT_MODAL: "AM/OPEN_EDIT_MODAL",
    AM_CLOSE_EDIT_MODAL: "AM/CLOSE_EDIT_MODAL",
    AM_TOGGLE_MODAL_SPINNER: "AM/TOGGLE_MODAL_SPINNER",
    AM_CHANGE_MODAL_STATE: "AM/AM_CHANGE_MODAL_STATE",
    AM_SET_ERROR_EDIT_MODAL: "AM/SET_ERROR_EDIT_MODAL",

    // Project management actions
    PM_SET_GRID_SOURCE: "PM/SET GRID SOURCE",
    PM_SET_GRID_VIEW: "PM/SET GRID VIEW",
    PM_TOGGLE_GRID_SPINNER: "PM/TOGGLE GRID SPINNER",
    PM_CLEAR_GRID_VIEW: "PM/CLEAR GRID VIEW",
    PM_CHANGE_GRID_FILTER: "PM/CHANGE GRID FILTER",
    PM_SET_MODAL_HEADER: "PM/SET MODAL HEADER",
    PM_OPEN_DELETE_MODAL: "PM_OPEN_DELETE_MODAL",
    PM_CLOSE_DELETE_MODAL: "PM_CLOSE_DELETE_MODAL",
    PM_OPEN_ARCHIVE_MODAL: "PM_OPEN_ARCHIVE_MODAL",
    PM_CLOSE_ARCHIVE_MODAL: "PM_CLOSE_ARCHIVE_MODAL",
    PM_ARCHIVE_MODAL_CHANGE_FIELDS: "PM_ARCHIVE_MODAL_CHANGE_FIELDS",
    PM_RESET_GRID_DATA: "PM_RESET_GRID_DATA",

    // Task management actions
    TM_SET_GRID_SOURCE: "TM/SET GRID SOURCE",
    TM_SET_GRID_VIEW: "TM/SET GRID VIEW",
    TM_TOGGLE_GRID_SPINNER: "TM/TOGGLE GRID SPINNER",
    TM_CLEAR_GRID_VIEW: "TM/CLEAR GRID VIEW",
    TM_CHANGE_GRID_FILTER: "TM/CHANGE GRID FILTER",
    TM_SET_MODAL_HEADER: "TM/SET MODAL HEADER",
    TM_SET_PROJECT_DATA: "TM/SET PROJECT DATA",
    TM_OPEN_DELETE_MODAL: "TM_OPEN_DELETE_MODAL",
    TM_CLOSE_DELETE_MODAL: "TM_CLOSE_DELETE_MODAL",
    TM_OPEN_ARCHIVE_MODAL: "TM_OPEN_ARCHIVE_MODAL",
    TM_CLOSE_ARCHIVE_MODAL: "TM_CLOSE_ARCHIVE_MODAL",
    TM_ARCHIVE_MODAL_CHANGE_FIELDS: "TM_ARCHIVE_MODAL_CHANGE_FIELDS",
    TM_EDIT_MODAL_OPEN: "TM_EDIT_MODAL_OPEN",
    TM_EDIT_MODAL_CLOSE: "TM_EDIT_MODAL_CLOSE",
    TM_EDIT_MODAL_OPEN_WITH_LOOKUPS: "TM_EDIT_MODAL_OPEN_WITH_LOOKUPS",
    TM_TOGGLE_EDIT_MODAL_SPINNER: "TM_TOGGLE_EDIT_MODAL_SPINNER",
    TM_RESET_GRID_DATA: "TM_RESET_GRID_DATA",

    // Scenario management actions
    SM_SET_GRID_SOURCE: "SM/SET GRID SOURCE",
    SM_SET_GRID_VIEW: "SM/SET GRID VIEW",
    SM_TOGGLE_GRID_SPINNER: "SM/TOGGLE GRID SPINNER",
    SM_CLEAR_GRID_VIEW: "SM/CLEAR GRID VIEW",
    SM_CHANGE_GRID_FILTER: "SM/CHANGE GRID FILTER",
    SM_SET_MODAL_HEADER: "SM/SET MODAL HEADER",
    SM_SET_TASK_DATA: "SM/SET TASK DATA",
    SM_CHANGE_SELECTED: "SM/CHANGE SELECTED",
    SM_SET_LOOKUP: "SM/SM_SET_LOOKUP",
    SM_SET_KEYWORDS: "SM/SM_SET_KEYWORDS",
    SM_SET_LINKS_MODAL_SOURCE: "SM/SM_SET_LINKS_MODAL_SOURCE",
    SM_SET_LINKS_MODAL_SCENARIO: "SM/SM_SET_LINKS_MODAL_SCENARIO",
    SM_TOGGLE_LINKS_MODAL_OPEN: "SM/TOGGLE LINKS MODAL OPEN",
    SM_TOGGLE_LINKS_MODAL_LOCKED: "SM/TOGGLE LINKS MODAL LOCKED",
    SM_TOGGLE_LINK_EDIT_MODAL_OPEN: "SM/SM_TOGGLE_LINK_EDIT_MODAL_OPEN",
    SM_TOGGLE_LINK_EDIT_MODAL_LOCKED: "SM/SM_TOGGLE_LINK_EDIT_MODAL_LOCKED",
    SM_EDIT_LINK: "SM/SM_EDIT_LINK",
    SM_SET_LINK_LOOKUP: "SM/SM_SET_LINK_LOOKUP",
    SM_RESET_LINK: "SM/SM_RESET_LINK",
    SM_RESET_LINK_MODAL: "SM/SM_RESET_LINK_MODAL",
    SM_SET_LINK_ERRORS: "SM/SM_SET_LINK_ERRORS",
    SM_SET_EXTERNAL_FUNCTIONS: "SM/SM_SET_EXTERNAL_FUNCTIONS",
    SM_TOGGLE_FUNCTION_MODAL_OPEN: 'SM/SM_TOGGLE_FUNCTION_MODAL_OPEN',
    SM_SET_FUNCTION_MODAL_SOURCE: "SM/SET_FUNCTION_MODAL_SOURCE",
    SM_RESET_GRID_DATA: "SM_RESET_GRID_DATA",
    SM_EDIT_MODAL_OPEN: 'SM_EDIT_MODAL_OPEN',
    SM_EDIT_MODAL_CLOSE: 'SM_EDIT_MODAL_CLOSE',
    SM_SET_SCENARIO_LOOKUPS: 'SM_SET_SCENARIO_LOOKUPS',

    SM_RESET: "SM/SM_RESET",

    // Scenario status actions
    SS_SET_GRID_SOURCE: "SS/SET GRID SOURCE",
    SS_TOGGLE_GRID_SPINNER: "SS/TOGGLE GRID SPINNER",
    SS_CLEAR_GRID_VIEW: "SS/CLEAR GRID VIEW",
    SS_CHANGE_GRID_FILTER: "SS/CHANGE GRID FILTER",
    SS_SET_SCENARIO_DATA: "SS/SET SCENARIO DATA",
    SS_SET_ADJOIN_SCENARIOS: "SS/SET ADJOIN SCENARIOS",
    SS_CHANGE_SELECTED: "SS/CHANGE SELECTED",
    SS_RESET: "SS/RESET",

    // reporting root actions
    REPORTING_SET_LOOKUPS: "REPORTING/SET LOOKUPS",
    REPORTING_SET_TASK_DATA: "REPORTING/SET TASK DATA",
    TOGGLE_SIDEBAR: "REPORTING/TOGGLE_SIDEBAR",

    //enelytix billing actions
    ENELYTIX_BILLING_SET_GRID_SOURCE: "ENELYTIX_BILLING/SET_GRID_SOURCE",
    ENELYTIX_BILLING_CHANGE_GRID_FILTER: "ENELYTIX_BILLING/CHANGE_GRID_FILTER",
    ENELYTIX_BILLING_TOGGLE_GRID_SPINNER: "ENELYTIX_BILLING/TOGGLE_GRID_SPINNER",
    ENELYTIX_BILLING_RESET_VIEW: "ENELYTIX_BILLING/RESET_VIEW",

    // cube management actions
    CM_SET_MASTER_SOURCE: "CM/SET MASTER SOURCE",
    CM_SET_MASTER_LEVEL: "CM/SET MASTER LEVEL",
    CM_SET_MASTER_SELECTED: "CM/SET MASTER SELECTED",
    CM_SET_MASTER_CHECKED: "CM/SET MASTER CHECKED",
    CM_RESET_MASTER_SOURCE: "CM/RESET MASTER SOURCE",
    CM_SET_MASTER_FILTER: "CM/SET MASTER FILTER",
    CM_SET_DETAIL_SOURCE: "CM/SET DETAIL SOURCE",
    CM_SET_DETAIL_LEVEL: "CM/SET DETAIL LEVEL",
    CM_SET_DETAIL_SELECTED: "CM/SET DETAIL SELECTED",
    CM_SET_DETAIL_CHECKED: "CM/SET DETAIL CHECKED",
    CM_RESET_DETAIL_SOURCE: "CM/RESET DETAIL SOURCE",
    CM_SET_DETAIL_FILTER: "CM/SET DETAIL FILTER",
    CM_SET_REPORTS_SOURCE: "CM/SET REPORTS SOURCE",
    CM_SET_SEGMENT_SOURCE: "CM/SET SEGMENT SOURCE",
    CM_SET_SEGMENT_FILTER: "CM/SET SEGMENT FILTER",
    CM_RESET_SEGMENT_SOURCE: "CM/RESET SEGMENT SOURCE",
    CM_SET_PARTITIONING_LEVEL: "CM/SET PARTITIONING LEVEL",
    CM_MODIFY_REPORTS_SOURCE: "CM/MODIFY REPORTS SOURCE",
    CM_SET_REPORTS_EDITABLE: "CM/SET REPORTS EDITABLE",
    CM_DELETE_REPORTS: "CM/DELETE REPORTS",
    CM_TOGGLE_MASTER_SPINNER: "CM/TOGGLE MASTER SPINNER",
    CM_TOGGLE_DETAIL_SPINNER: "CM/TOGGLE_DETAIL SPINNER",
    CM_TOGGLE_PARTITION_SPINNER: "CM/TOGGLE PARTITION SPINNER",
    CM_SET_EDITED_STATE: "CM/SET EDITED STATE",
    CM_CHANGE_MASTER_FILTER: "CM/CHANGE MASTER FILTER",
    CM_CHANGE_DETAIL_FILTER: "CM/CHANGE DETAIL FILTER",
    CM_CHANGE_STATUS_FILTER: "CM/CHANGE STATUS FILTER",
    CM_SET_LOOKUPS: "CM/SET LOOKUPS",
    CM_SET_DEFAULT_REPORTS_FLAG: "CM/SET DEFAULT REPORTS FLAG",

    // cube groups actions
    CG_SWITCH_DETAIL: "CG/SWITCH DETAIL",
    CG_MASTER_SELECTED: "CG/MASTER SELECTED",
    CG_DETAIL_SELECTED: "CG/DETAIL SELECTED",
    CG_TOGGLE_MASTER_SPINNER: "CG/TOGGLE MASTER SPINNER",
    CG_TOGGLE_DETAIL_SPINNER: "CG/TOGGLE DETAIL SPINNER",
    CG_TOGGLE_REPORTS_SPINNER: "CG/TOGGLE REPORTS SPINNER",
    CG_SET_MASTER_SOURCE: "CG/SET MASTER SOURCE",
    CG_SET_DETAIL_SOURCE: "CG/SET DETAIL SOURCE",
    CG_SET_REPORTS_SOURCE: "CG/SET REPORTS SOURCE",
    CG_MASTER_CHECKED: "CG/MASTER CHECKED",
    CG_DETAIL_CHECKED: "CG/DETAIL CHECKED",
    CG_REPORTS_LOADED: "CG/REPORTS LOADED",
    CG_REPORTS_UPDATED: "CG/REPORTS UPDATED",
    CG_SET_EDITED_STATE: "CG/SET EDITED STATE",
    CG_SET_MASTER_FILTER: "CG/SET MASTER FILTER",
    CG_SET_DETAIL_FILTER: "CG/SET DETAIL FILTER",
    CG_SET_REPORTS_FILTER: "CG/SET REPORTS FILTER",
    CG_SET_LOOKUPS: "CG/SET LOOKUPS",

    // static reports tab
    SR_SET_MASTER_LEVEL: "SR/SET MASTER LEVEL",
    SR_TOGGLE_MASTER_SPINNER: "SR/TOGGLE MASTER SPINNER",
    SR_SET_LOOKUPS: "SR/SET LOOKUPS",
    SR_RESET: "SR/SR_RESET",
    SR_SET_SETTINGS: "SR/SR_SET_SETTINGS",
    SR_SET_CASES: "SR/SR_SET_CASES",
    SR_SET_CASES_SELECTED: "SR/SR_SET_CASES_SELECTED",
    SR_SET_CASES_FILTER: "SR/SET_CASES_FILTER",
    SR_SET_TABLES: "SR/SR_SET_TABLES",
    SR_ADD_NEW_TAB: 'SR/SR_ADD_NEW_TAB',
    SR_CHANGE_TAB_CONTENTS: 'SR/SR_CHANGE_TAB_CONTENTS',
    SR_SET_ACTIVE_TAB: 'SR/SR_SET_ACTIVE_TAB',
    SR_SET_RESULT_DATA: 'SR/SR_SET_RESULT_DATA',
    SR_CLOSE_TAB: 'SR/SR_CLOSE_TAB',
    SR_SET_GRID_COLUMNS: 'SR/SR_SET_GRID_COLUMNS',
    SR_SET_GRID_ROWS: 'SR/SR_SET_GRID_ROWS',
    SR_RESET_EDITOR_TAB: 'SR/SR_RESET_EDITOR_TAB',
    SR_SET_STATIC_REPORTS: "SR/SR_SET_STATIC_REPORTS",
    SR_SET_TEMPLATE: "SR/SR_SET_TEMPLATE",
    SR_SET_TEMPLATE_SELECTED: "SR/SR_SET_TEMPLATE_SELECTED",
    SR_SET_REPORT_SELECTED: "SR/SR_SET_REPORT_SELECTED",
    SR_SET_ATHENA_HISTORIES: "SR/SR_SET_ATHENA_HISTORIES",
    SR_SET_ATHENA_FILTER: "SR/SR_SET_ATHENA_FILTER",
    SR_TOGGLE_RESULT_SPINNER: "SR/SR_TOGGLE_RESULT_SPINNER",
    SR_OPEN_EDIT_MODAL: "SR/SR_OPEN_EDIT_MODAL",
    SR_CLOSE_EDIT_MODAL: "SR/SR_CLOSE_EDIT_MODAL",
    SR_EDIT_MODAL_CHANGE_FIELDS: "SR/SR_EDIT_MODAL_CHANGE_FIELDS",
    SR_SET_TABS: "SR/SR_SET_TABS",
    SR_SET_END_QUERYS: "SR/SR_SET_END_QUERYS",
    SR_RESET_RESULT_DATA: "SR/SR_RESET_RESULT_DATA",
    SR_OPEN_DASHBOARD_MODAL: "SR/SR_OPEN_DASHBOARD_MODAL",
    SR_CLOSE_DASHBOARD_MODAL: "SR/SR_CLOSE_DASHBOARD_MODAL",
    SR_DASHBOARD_MODAL_CHANGE_FIELDS: "SR/SR_DASHBOARD_MODAL_CHANGE_FIELDS",
    SR_RESET_DASHBOARD_MODAL: "SR/SR_RESET_DASHBOARD_MODAL",

    // Scenario group actions
    SG_SET_GRID_SOURCE: "SG/SET GRID SOURCE",
    SG_TOGGLE_GRID_SPINNER: "SG/TOGGLE GRID SPINNER",
    SG_CHANGE_GRID_FILTER: "SG/CHANGE GRID FILTER",
    SG_SET_MODAL_HEADER: "SG/SET MODAL HEADER",
    SG_SET_TASK_DATA: "SG/SET TASK DATA",
    SG_CHANGE_SELECTED: "SG/CHANGE SELECTED",
    SG_TOGGLE_LINKS_MODAL_OPEN: "SG/TOGGLE LINKS MODAL OPEN",
    SG_TOGGLE_LINKS_MODAL_LOCKED: "SG/TOGGLE LINKS MODAL LOCKED",
    SG_SET_LINKS_MODAL_SOURCE: "SG/TOGGLE LINKS MODAL SOURCE",
    SG_TOGGLE_INPUTS_MODAL_OPEN: "SG/TOGGLE INPUTS MODAL OPEN",
    SG_TOGGLE_INPUTS_MODAL_LOCKED: "SG/TOGGLE INPUTS MODAL LOCKED",
    SG_SET_INPUTS_MODAL_SOURCE: "SG/TOGGLE INPUTS MODAL SOURCE",
    SG_TOGGLE_RESULTS_MODAL_OPEN: 'SG/TOGGLE RESULTS MODAL OPEN',

    SG_SET_FILES_MODAL_SOURCE: "SG/SET FILES MODAL SOURCE",
    SG_RESET_FILES_MODAL: "SG/SG RESET FILES MODAL",

    SG_CHANGE_ROW: "SG/CHANGE FILE VALUE",
    SG_DELETE_ROW: "SG/DELETE ROW",
    SG_SET_FILES_MODAL_FILTER: "SG/SET FILES MODAL FILTER",
    SG_UPDATE_FILES_MODAL_FILTER: "SG/UPDATE FILES MODAL FILTER",
    SG_ADD_ROW: "SG/ADD ROW",

    SG_SET_FILES_MODAL_ERRORS: "SG/SET FILES MODAL ERRORS",
    SG_RESET_FILES_MODAL_ERRORS: "SG/RESET FILES MODAL ERRORS",

    SG_TOGGLE_FILES_MODAL_OPEN: "SG/TOGGLE FILES MODAL OPEN",
    SG_SET_FILES_SPECIFICATION: "SG/SET FILES SPECIFICATION",
    SG_SET_FILES_MODAL_GROUP: "SG/SET FILES MODAL GROUP",
    SG_SET_FILES_MODAL_TAB: "SG/SET FILES MODAL TAB",
    SG_TOGGLE_FILES_MODAL_LOCKED: "SG/TOGGLE FILES MODAL LOCKED",

    SG_RESET: 'SG/SG_RESET',
    SG_TOGGLE_RESULTS_MODAL_LOCKED: 'SG/TOGGLE RESULTS MODAL LOCKED',
    SG_SET_RESULTS_MODAL_SOURCE: 'SG/SET RESULTS MODAL SOURCE',
    SG_SET_SELECTED_GROUP: 'SG/SET SELECTED GROUP',

    SG_SET_EXTERNAL_FUNCTIONS: 'SG/SET EXTERNAL FUNCTIONS',
    SG_TOGGLE_FUNCTION_MODAL_OPEN: 'SG/TOGGLE FUNCTION MODAL OPEN',
    SG_SET_FUNCTION_MODAL_SOURCE: 'SG/SET FUNCTION MODAL SOURCE',

    // Tag actions
    TAG_SET_GRID_SOURCE: "TAG/SET GRID SOURCE",
    TAG_TOGGLE_GRID_SPINNER: "TAG/TOGGLE GRID SPINNER",
    TAG_CHANGE_GRID_FILTER: "TAG/CHANGE GRID FILTER",
    TAG_SET_MODAL_HEADER: "TAG/SET MODAL HEADER",
    TAG_SET_TASK_DATA: "TAG/SET TASK DATA",
    TAG_CHANGE_SELECTED: "TAG/CHANGE SELECTED",
    TAG_TOGGLE_LINKS_MODAL_OPEN: "TAG/TOGGLE LINKS MODAL OPEN",
    TAG_TOGGLE_LINKS_MODAL_LOCKED: "TAG/TOGGLE LINKS MODAL LOCKED",
    TAG_SET_LINKS_MODAL_SOURCE: "TAG/TOGGLE LINKS MODAL SOURCE",
    TAG_SET_LINKS_MODAL_TAG: "TAG/SET LINKS MODAL TAG",
    TAG_SET_LINKS_MODAL_LOOKUP: "TAG/TAG_SET_LINKS_MODAL_LOOKUP",
    TAG_TOGGLE_LINK_EDIT_MODAL_OPEN: "TAG/TAG_TOGGLE_LINK_EDIT_MODAL_OPEN",
    TAG_EDIT_LINK: "TAG/TAG_EDIT_LINK",
    TAG_TOGGLE_LINK_EDIT_MODAL_LOCKED: "TAG/TAG_TOGGLE_LINK_EDIT_MODAL_LOCKED",
    TAG_SET_LINK_LOOKUP: "TAG/TAG_SET_LINK_EDIT_MODAL_LOOKUP",
    TAG_RESET_LINK: "TAG/TAG_RESET_LINK",
    TAG_RESET_LINK_MODAL: "TAG/TAG_RESET_LINK_MODAL",
    TAG_SET_LINK_ERRORS: "TAG/TAG_SET_LINK_ERRORS",
    TAG_CHANGE_LINKS_MODAL_SEARCH: "TAG/TAG_CHANGE_LINKS_MODAL_SEARCH",

    // probes
    PROBE_SET_MASTER_LEVEL: "PROBE/SET MASTER LEVEL",
    PROBE_SET_MASTER_SELECTED: "PROBE/SET MASTER SELECTED",
    PROBE_TOGGLE_MASTER_SPINNER: "PROBE/TOGGLE MASTER SPINNER",
    PROBE_TOGGLE_DETAIL_SPINNER: "PROBE/TOGGLE DETAIL SPINNER",
    PROBE_SET_DETAIL_SELECTED: "PROBE/SET DETAIL SELECTED",
    PROBE_SET_DETAIL_SOURCE: "PROBE/SET DETAIL SOURCE",
    PROBE_SET_MASTER_SOURCE: "PROBE/SET MASTER SOURCE",
    PROBE_SET_DETAIL_LEVEL: "PROBE/SET DETAIL LEVEL",
    PROBE_SET_MASTER_FILTER: "PROBE/SET MASTER FILTER",
    PROBE_SET_DETAIL_FILTER: "PROBE/SET DETAIL FILTER",
    PROBE_SET_LOOKUPS: "PROBE/SET LOOKUPS",
    PROBE_RESET: "PROBE/RESET",
    PROBE_SET_SELECTED_PROBE_GROUP: "PROBE/PROBE_SET_SELECTED_PROBE_GROUP",
    PROBE_SET_PROBE_GROUP_MODAL_SOURCE: "PROBE/PROBE_SET_PROBE_GROUP_MODAL_SOURCE",
    PROBE_SET_PROBE_GROUP_MODAL_ERRORS: "PROBE/PROBE_SET_PROBE_GROUP_MODAL_ERRORS",
    PROBE_TOGGLE_PROBE_GROUP_NODAL_LOCKED: "PROBE/PROBE_TOGGLE_PROBE_GROUP_NODAL_LOCKED",
    PROBE_TOGGLE_PROBE_GROUP_MODAL_OPEN: "PROBE/PROBE_TOGGLE_PROBE_GROUP_MODAL_OPEN",

    PROBE_SET_RUN_PROBES_MODAL_SOURCE: "PROBE/PROBE_SET_RUN_PROBES_MODAL_SOURCE",
    PROBE_TOGGLE_RUN_PROBES_NODAL_LOCKED: "PROBE/PROBE_TOGGLE_RUN_PROBES_NODAL_LOCKED",
    PROBE_TOGGLE_RUN_PROBES_MODAL_OPEN: "PROBE/PROBE_TOGGLE_RUN_PROBES_MODAL_OPEN",
    PROBE_SET_RUN_PROBES_MODAL_ERRORS: "PROBE/PROBE_SET_RUN_PROBES_MODAL_ERRORS",

    PROBE_TOGGLE_RUN_PROBES_CONFIRMATION_DIALOG_OPEN: "PROBE/PROBE_TOGGLE_RUN_PROBES_CONFIRMATION_DIALOG_OPEN",

    // OTI actions
    OTI_SWITCH_DETAIL: "OTI/SWITCH DETAIL",
    OTI_MASTER_SELECTED: "OTI/MASTER SELECTED",
    OTI_MASTER_CHECKED: "OTI/MASTER CHECKED",
    OTI_DETAIL_SELECTED: "OTI/DETAIL SELECTED",
    OTI_DETAIL_CHECKED: "OTI/DETAIL CHECKED",
    OTI_TOGGLE_MASTER_SPINNER: "OTI/TOGGLE MASTER SPINNER",
    OTI_TOGGLE_DETAIL_SPINNER: "OTI/TOGGLE DETAIL SPINNER",
    OTI_SET_MASTER_SOURCE: "OTI/SET MASTER SOURCE",
    OTI_SET_DETAIL_SOURCE: "OTI/SET DETAIL SOURCE",
    OTI_SET_MASTER_FILTER: "OTI/SET MASTER FILTER",
    OTI_SET_DETAIL_FILTER: "OTI/SET DETAIL FILTER",
    OTI_RESET_MASTER_FILTER: "OTI/RESET MASTER FILTER",
    OTI_RESET_DETAIL_FILTER: "OTI/RESET DETAIL FILTER",
    OTI_TOGGLE_CONFIGURATION_MODAL: "OTI/TOGGLE CONFIGURATION MODAL",
    OTI_TOGGLE_CONFIGURATION_MODAL_LOCKED: "OTI/TOGGLE CONFIGURATION MODAL LOCKED",
    OTI_SET_CONFIGURATION_MODAL_LOOKUP: "OTI/SET CONFIGURATION MODAL LOOKUP",
    OTI_SET_CONFIGURATION_MODAL_SOURCE: "OTI/SET CONFIGURATION MODAL SOURCE",
    OTI_SET_CONFIGURATION_MODAL_ERRORS: "OTI/SET CONFIGURATION MODAL ERRORS",
    OTI_SET_CONFIGURATION_MODAL_HEADER: "OTI/SET CONFIGURATION MODAL HEADER",
    OTI_SET_DATA_TYPE_FILTERS: "OTI/SET DATA TYPE FILTERS",
    OTI_TOGGLE_AREAS_EDIT_MODAL: "OTI/TOGGLE AREAS EDIT MODAL",

    // Resource Adequacy actions
    SET_RUN_CONFIGS: "SET_RUN_CONFIGS",
    SET_RUN_CONFIG_TO_EDIT: 'SET_RUN_CONFIG_TO_EDIT',
    RUN_CONFIGS_TOGGLE_SPINNER: "RUN_CONFIGS_TOGGLE_SPINNER",
    RA_SET_MODAL_HEADER: "RA_SET_MODAL_HEADER",
    RA_SET_TASK_DATA: 'RA_SET_TASK_DATA',
    RA_RESET: 'RA_RESET',
    RA_OPEN_CONFIG_MODAL: 'RA_OPEN_CONFIG_MODAL',
    RA_CLOSE_CONFIG_MODAL: 'RA_CLOSE_CONFIG_MODAL',
    RA_EDIT_MODAL_FIELDS: 'RA_EDIT_MODAL_FIELDS',
    RA_SET_SCENARIOS: "RA_SET_SCENARIOS",
    RA_SET_SCENARIO_GROUPS: "RA_SET_SCENARIO_GROUPS",
    RA_SET_PARTITIONING: "RA_SET_PARTITIONING",

    //Dashboard Management
    DASHBOARD_SET_DATA: "DASHBOARD_SET_DATA",
    DASHBOARD_SET_SELECT_DATA: "DASHBOARD_SET_SELECT_DATA",
    DASHBOARD_MGMT_RESET_SCREEN: "DASHBOARD_MGMT_RESET_SCREEN",
    DASHBOARD_TOGGLE_SIDEBAR: "DASHBOARD_TOGGLE_SIDEBAR",
    DASHBOARD_TOGGLE_NODE: 'DASHBOARD_TOGGLE_NODE',
    DASHBOARD_SET_TASK: "DASHBOARD_SET_TASK",

    //One-line diagram
    ONE_LINE_DIAG_SET_CURRENT_TAB: "ONE_LINE_DIAG_SET_CURRENT_TAB",
    ONE_LINE_DIAG_SET_SCENARIOS: "ONE_LINE_DIAG_SET_SCENARIOS",
    ONE_LINE_DIAG_SET_AREAS: "ONE_LINE_DIAG_SET_AREAS",
    ONE_LINE_DIAG_SET_UNIT_TYPES: "ONE_LINE_DIAG_SET_UNIT_TYPES",
    ONE_LINE_DIAG_UPDATE_SELECTION: "ONE_LINE_DIAG_UPDATE_SELECTION",
    ONE_LINE_DIAG_SET_NODES: "ONE_LINE_DIAG_SET_NODES",
    ONE_LINE_DIAG_RESET: "ONE_LINE_DIAG_RESET",
    ONE_LINE_DIAG_SET_DIAGRAM_ITEM: "ONE_LINE_DIAG_SET_DIAGRAM_ITEM",
};

export default ActionTypes;
