import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DashboardMgmtState,
    ReleaseGroup,
    CustomerAccount,
    Table,
    FetchTablesFilter
} from './types';
import { DashboardList } from 'store/dashboardViewer/types';

const initialState: DashboardMgmtState = {
    releaseGroups: [],
    releaseGroupSelected: 0,
    customerAccounts: [],
    customersSelected: [],
    customerLoader: false,
    dashboards: {
        items: [],
        totalCount: 0,
    },
    tables: {
        items: [],
        totalCount: 0,
        filter: {
            releaseGroupIds: []
        }
    },
    dashboardSelected: '',
    copyDashboardModal: {
        spinner: false,
        isOpen: false,
        dashboards: [],
        releaseGroups: [],
        releaseGroupSelected: 0,
        dashboardsSelected: [],
    },
    linkModal: {
        spinner: false,
        isOpen: false,
        quicksightId: '',
        dashboardId: '',
        releaseGroupId: 0,
    },
    editModal: {
        spinner: false,
        isOpen: false,
        title: '',
        tables: {
            items: [],
            totalCount: 0,
            filter: {
                releaseGroupIds: []
            }
        },
        dashboardData: null
    },
    tableEditModal: {
        spinner: false,
        isOpen: false,
        title: '',
        dashboardTable: {
            tableId: '',
            name: '',
            description: '',
            releaseGroupId: 0,
            createdDate: '',
            updatedDate: null,
            deletedDate: null,
            deletedReasonText: null,
            qsDashboardIds: [],
            scenarios: [],
            sqlQuery: ''
        }
    }
};

const dashboardMgmtSlice = createSlice({
    name: 'dashboardMgmt',
    initialState,
    reducers: {
        setReleaseGroups(state, action: PayloadAction<ReleaseGroup[]>) {
            state.releaseGroups = action.payload;
        },
        setCustomerAccounts(state, action: PayloadAction<CustomerAccount[]>) {
            state.customerAccounts = action.payload;
        },
        setCustomersSelected(state, action: PayloadAction<number[]>) {
            state.customersSelected = action.payload;
        },
        setReleaseGroupSelected(state, action: PayloadAction<number>) {
            state.releaseGroupSelected = action.payload;
        },
        setDashboardList(state, action: PayloadAction<DashboardList>) {
            state.dashboards = { ...action.payload };
        },
        setDashboardSelected(state, action: PayloadAction<string>) {
            state.dashboardSelected = action.payload;
        },
        changeCopyDashboardModal(state, action: PayloadAction<Partial<DashboardMgmtState['copyDashboardModal']>>) {
            state.copyDashboardModal = {
                ...state.copyDashboardModal,
                ...action.payload,
            };
        },
        closeCopyDashboardModal(state) {
            state.copyDashboardModal = { ...initialState.copyDashboardModal };
        },
        toggleCopyModalSpinner(state, action: PayloadAction<boolean>) {
            state.copyDashboardModal.spinner = action.payload;
        },
        changeLinkModal(state, action: PayloadAction<Partial<DashboardMgmtState['linkModal']>>) {
            state.linkModal = {
                ...state.linkModal,
                ...action.payload,
            };
        },
        closeLinkModal(state) {
            state.linkModal = { ...initialState.linkModal };
        },
        changeEditModal(state, action: PayloadAction<Partial<DashboardMgmtState['editModal']>>) {
            state.editModal = {
                ...state.editModal,
                ...action.payload,
            };
        },
        closeEditModal(state) {
            state.editModal = { ...initialState.editModal };
        },
        toggleCustomerLoader(state) {
            state.customerLoader = !state.customerLoader
        },
        setTableList(state, action: PayloadAction<{ items: Table[]; totalCount: number; filter: FetchTablesFilter }>) {
            state.tables = { ...action.payload };
        },
        changeTableEditModal(state, action: PayloadAction<Partial<DashboardMgmtState['tableEditModal']>>) {
            state.tableEditModal = { ...state.tableEditModal, ...action.payload, };
        },
        closeTableEditModal(state) {
            state.tableEditModal = { ...initialState.tableEditModal };
        },
        resetScreen() {
            return initialState;
        },
    },
});

export const actions = dashboardMgmtSlice.actions;
export default dashboardMgmtSlice.reducer;
