import { fetch, fetchParallel, mapLookup } from "common/helpers";
import {
    scenarioSvc as service,
    lookupSvc,
    taskSvc,
    dashboardsService as dshbSvc,
    dashboardGroupsService as dshbGrSvc,
    snowflakeService
} from "services/serviceStorage";
import {
    FetchScenarioEditLookupsParams,
    ScenarioEditLookupsResponse,
    FetchScenariosParams,
    FetchScenariosResponse,
    FetchExternalFunctionsParams,
    FetchExternalFunctionsResponse,
    DeleteScenariosParams,
    FetchScenarioParams,
    SaveScenarioParams,
    CopyInputsParams,
    ValidateScenariosParams,
    CheckInstanceCapacityParams,
    FetchInputFilesParams,
    File,
    UploadInputParams,
    DownloadInputsParams,
    RunExternalFunctionsParams,
    FetchLinksParams,
    Link
} from "./types";
import { BaseApiResponse, Scenario } from "common/types";

export const fetchScenarioEditLookups = ({ taskId, releaseGroupId, spinnerToggle, successCallback }: FetchScenarioEditLookupsParams) => {
    const requests = [
        lookupSvc.getPartitioningModes,
        lookupSvc.getTimeUnits,
        () => lookupSvc.getReleases(taskId),
        () => lookupSvc.getCubeTemplates(taskId),
        () => lookupSvc.getReportTemplates(taskId),
        lookupSvc.getVMGroups,
        () => taskSvc.getTask(taskId),
        () => dshbSvc.getDashboardsList({ releaseGroupIds: [releaseGroupId] }),
        () => dshbGrSvc.getDashboardGroupsList({ releaseGroupIds: [releaseGroupId] }),
    ];

    if (taskId) {
        requests.push(() => taskSvc.getScenarioGroupsLookup(taskId));
    }

    return fetchParallel({
        spinnerToggle,
        requests,
        callback: ([partitioning, timeUnit, releases, cubeTemplates, reportTemplates, vmGroups, task, dashboards, dashboardGroups, groups]: ScenarioEditLookupsResponse) =>
            successCallback({
                partitioning: partitioning.result.map(mapLookup),
                timeUnits: timeUnit.result.map(mapLookup),
                releases: releases.result.map(r => ({
                    ...r,
                    key: r.id,
                    displayName: r.displayName,
                    releaseGroupId: r.releaseGroupId,
                })),
                cubeTemplates: cubeTemplates.result.map(mapLookup),
                reportTemplates: reportTemplates.result.map(r => ({
                    key: r.id,
                    displayName: r.displayName,
                    releaseGroupId: r.releaseGroupId,
                    isGlobal: r.isGlobal,
                })),
                vmGroups: vmGroups.result.map(mapLookup),
                task: task.result,
                dashboards: dashboards?.result.items.map((d) => ({
                    value: d.dashboardId,
                    label: d.dashboardName,
                })) ?? [],
                dashboardGroups: dashboardGroups?.result.items.map((g) => ({
                    value: g.groupId,
                    label: g.groupName,
                    linkedIds: g.dashboards.map(d => d.id),
                })) ?? [],
                groups: groups?.result.map(mapLookup) ?? []
            }),
    });
}

export const fetchScenarios = (params: FetchScenariosParams) => {
    console.trace();
    console.log('fetchScenarios filter: ', params.filter);
    return fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: FetchScenariosResponse) => params.successCallback(r.result),
        request: () => service.getScenarios(params.filter),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback
    })
};

export const fetchExternalFunctions = (params: FetchExternalFunctionsParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: FetchExternalFunctionsResponse) => params.successCallback(r.result),
        request: () => service.getExternalFunctions(params.taskId),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback
    });

export const deleteScenarios = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: DeleteScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.deleteScenarios(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const fetchScenario = ({ scenarioId, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchScenarioParams) =>
    fetch({
        spinnerToggle,
        request: () => service.getScenario(scenarioId),
        callback: (r: BaseApiResponse<Scenario>) => successCallback(r.result),
        validationCallback: validationCallback,
        errorCallback: errorCallback,
    });

export const saveScenario = ({ scenario, spinnerToggle, validationCallback, successCallback, errorCallback }: SaveScenarioParams) =>
    fetch({
        spinnerToggle,
        request: () => service.putScenario(scenario),
        validationCallback: validationCallback,
        errorCallback: errorCallback,
        callback: (r: BaseApiResponse<number>) => successCallback(r.result),
    });

export const copyInputs = ({ sourceId, destinationId, spinnerToggle, successCallback, errorCallback, validationCallback }: CopyInputsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.copyInputs({ sourceId, destinationId }),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const validateScenarios = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle: spinnerToggle,
        request: () => service.validateScenarios(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const runScenarios = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.runScenarios(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const stopScenarios = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.stopScenarios(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });


export const checkInstanceCapacity = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: CheckInstanceCapacityParams) =>
    fetch({
        spinnerToggle,
        request: () => service.checkInstanceCapacity(scenarioIds),
        callback: (r: BaseApiResponse<{ maxInstances: number; instanceCount: number }>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const fetchInputFiles = ({ scenarioId, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchInputFilesParams) =>
    fetch({
        spinnerToggle,
        request: () => service.getInputs(scenarioId),
        callback: (r: BaseApiResponse<File[]>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const uploadInputs = ({ scenarioId, files, spinnerToggle, successCallback, errorCallback, validationCallback }: UploadInputParams) =>
    fetch({
        spinnerToggle,
        request: () => service.uploadInputs({ scenarioId, files }),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const deleteInputs = ({ scenarioId, files, spinnerToggle, successCallback, errorCallback, validationCallback }: DownloadInputsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.deleteInputs({ scenarioId, files }),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const downloadInputs = ({ scenarioId, files, spinnerToggle, successCallback, errorCallback, validationCallback }: DownloadInputsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.downloadInputs({ scenarioId, files }),
        callback: (r: BaseApiResponse<string[]>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const loadResults = ({ scenarioIds, spinnerToggle, successCallback, errorCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.loadResults(scenarioIds),
        validationCallback: errorCallback,
        callback: successCallback,
        errorCallback: errorCallback
    });

export const runForceMerge = ({ scenarioIds, spinnerToggle, successCallback, errorCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.runForceMerge(scenarioIds),
        validationCallback: errorCallback,
        callback: successCallback,
        errorCallback: errorCallback
    });

export const restartFailedCases = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.restartFailedCases(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const runExternalFunctions = ({ request, spinnerToggle, successCallback, errorCallback }: RunExternalFunctionsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.runExternalFunctions(request),
        validationCallback: errorCallback,
        callback: successCallback,
        errorCallback: errorCallback
    });

export const loadDashboardsSnowflake = ({ scenarioIds, spinnerToggle, successCallback, errorCallback, validationCallback }: ValidateScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => snowflakeService.loadScenarioFiles(scenarioIds),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const fetchLinks = ({ filter, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchLinksParams) =>
    fetch({
        spinnerToggle,
        callback: (r: BaseApiResponse<{ items: Link[]; totalCount: number; }>) => successCallback(r.result),
        request: () => service.getLinks(filter),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });