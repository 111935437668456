import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CasesLoadedSate,
    DashboardList,
    DashboardGroupList,
    DashboardViewerState,
    GroupData,
    FilterCasesLoadedSate
} from './types';
import { Task } from 'common/types';

const initialState: DashboardViewerState = {
    isViewerSidebarOpen: true,
    isCasesSidebarOpen: false,
    task: null,
    scenarios: {
        data: [],
        total: 0,
        filter: {
            pageNumber: 1,
            pageSize: 20,
        },
    },
    scenarioSelected: [],
    dashboardSelected: '',
    dashboards: {
        items: [],
        totalCount: 0,
    },
    dashboardGroups: {
        items: [],
        totalCount: 0,
        filter: {
            pageNumber: 1,
            pageSize: 20,
            searchString: '',
        },
    },
    groupEditModal: {
        spinner: false,
        isOpen: false,
        title: '',
        dashboards: {
            items: [],
            totalCount: 0,
            filter: {
                pageNumber: 1,
                pageSize: 20,
                searchString: '',
            },
        },
        groupData: {
            releaseGroupId: null,
            groupName: '',
            groupId: '',
            existDashboards: [],
        }
    },
};

const dashboardViewerSlice = createSlice({
    name: 'dashboardViewer',
    initialState,
    reducers: {
        toggleViewerSidebar(state) {
            state.isViewerSidebarOpen = !state.isViewerSidebarOpen;
        },
        toggleCasesSidebar(state) {
            state.isCasesSidebarOpen = !state.isCasesSidebarOpen;
        },
        setTask(state, action: PayloadAction<Task>) {
            state.task = action.payload;
        },
        setScenarios(state, action: PayloadAction<{
            data: CasesLoadedSate[];
            total: number;
            filter?: FilterCasesLoadedSate;
        }>) {
            state.scenarios.data = action.payload.data;
            state.scenarios.total = action.payload.total;
            state.scenarios.filter = action.payload.filter || initialState.scenarios.filter;
        },
        setScenariosSelected(state, action: PayloadAction<number[]>) {
            state.scenarioSelected = action.payload ?? [];
        },
        setScenariosFilter(state, action: PayloadAction<FilterCasesLoadedSate>) {
            state.scenarios.filter = {
                ...state.scenarios.filter,
                ...action.payload,
            };
        },
        setDashboardList(state, action: PayloadAction<DashboardList>) {
            state.dashboards = { ...action.payload };
        },
        setDashboardSelected(state, action: PayloadAction<string>) {
            state.dashboardSelected = action.payload;
        },
        setDashboardGroupList(state, action: PayloadAction<DashboardGroupList>) {
            state.dashboardGroups = {
                ...action.payload,
                filter: action.payload.filter ?? initialState.dashboardGroups.filter,
            };
        },
        openGroupEditModal(state, action: PayloadAction<{ title: string }>) {
            state.groupEditModal.isOpen = true;
            state.groupEditModal.title = action.payload.title;
        },
        toggleModalSpinner(state, action: PayloadAction<boolean>) {
            state.groupEditModal.spinner = action.payload;
        },
        setDashboardListToModal(state, action: PayloadAction<DashboardList>) {
            state.groupEditModal.dashboards = {
                ...action.payload,
                filter: action.payload.filter ?? initialState.groupEditModal.dashboards.filter,
            };
        },
        changeGroupData(
            state: DashboardViewerState,
            action: PayloadAction<Partial<GroupData>>
        ) {
            state.groupEditModal.groupData = {
                ...state.groupEditModal.groupData,
                ...action.payload,
            };
        },
        closeGroupEditModal(state) {
            state.groupEditModal = { ...initialState.groupEditModal };
        },
        resetScreen() {
            return initialState;
        },
    },
});

export const actions = dashboardViewerSlice.actions;
export default dashboardViewerSlice.reducer;
