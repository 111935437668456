import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { staticReportLevels } from 'common/enums';
import {
    StaticReportsSnowflakeState,
    Template,
    Scenario,
    StaticReport,
    Filter,
    ReportHistoryItem
} from './types';

const initialState: StaticReportsSnowflakeState = {
    lookup: {
        taskId: 0,
        releaseGroupId: 0,
        dbName: '',
    },
    scenarios: {
        data: [],
        total: 0,
        filter: {
            pageNumber: 1,
            pageSize: 20,
            searchString: '',
            mergedOnly: true,
        }
    },
    scenariosSelected: [],
    templates: {
        data: [],
        total: 0,
    },
    templatesSelected: [],
    staticReports: {
        data: [],
        total: 0,
    },
    reportsSelected: [],
    snowflakeHistories: {
        data: [],
        total: 0,
        filter: {
            pageNumber: 1,
            pageSize: 50,
            searchString: '',
        }
    },
    masterSpinner: false,
    masterLevel: staticReportLevels.reportHistory,
    editReportModal: {
        open: false,
        title: '',
        templates: [],
        item: {
            staticReportName: '',
            description: '',
            query: '',
            templateIds: [],
            global: false,
        }
    },
    editTemplateModal: {
        open: false,
        title: '',
        item: {
            templateName: '',
            description: '',
            isGlobal: false,
        }
    }
};

const staticReportsSnowflakeSlice = createSlice({
    name: 'staticReportsSnowflake',
    initialState,
    reducers: {
        setLookup(state, action: PayloadAction<{ taskId: number; releaseGroupId: number; dbName: string; }>) {
            state.lookup = action.payload;
        },
        setScenarios(state, action: PayloadAction<{ data: Scenario[]; total: number; filter?: Filter }>) {
            state.scenarios.data = action.payload.data;
            state.scenarios.total = action.payload.total;
            state.scenarios.filter = action.payload.filter ? action.payload.filter : state.scenarios.filter;
        },
        setScenariosSelection(state, action: PayloadAction<number[]>) {
            state.scenariosSelected = action.payload;
        },
        setScenariosFilter(state, action: PayloadAction<Filter>) {
            state.scenarios.filter = { ...state.scenarios.filter, ...action.payload };
        },
        setTemplates(state, action: PayloadAction<{ data: Template[]; total: number }>) {
            state.templates = action.payload;
        },
        setTemplateSelection(state, action: PayloadAction<number[]>) {
            state.templatesSelected = action.payload;
        },
        setStaticReports(state, action: PayloadAction<{ data: StaticReport[]; total: number }>) {
            state.staticReports = action.payload;
        },
        setReportSelection(state, action: PayloadAction<number[]>) {
            state.reportsSelected = action.payload;
        },
        setSnowflakeHistories(state, action: PayloadAction<{ data: ReportHistoryItem[]; total: number; filter?: Filter }>) {
            state.snowflakeHistories.data = action.payload.data;
            state.snowflakeHistories.total = action.payload.total;
            state.snowflakeHistories.filter = action.payload.filter ? action.payload.filter : state.snowflakeHistories.filter;
        },
        setSnowflakeHistoriesFilter(state, action: PayloadAction<Filter>) {
            state.snowflakeHistories.filter = { ...state.snowflakeHistories.filter, ...action.payload };
        },
        changeMasterLevel(state, action: PayloadAction<number>) {
            state.masterLevel = action.payload;
        },
        openReportEditModal(
            state,
            action: PayloadAction<{ title: string; item?: Partial<StaticReport>; templates?: Template[]; }>
        ) {
            state.editReportModal.open = true;
            state.editReportModal.title = action.payload.title;
            state.editReportModal.item = {
                staticReportName: action.payload.item?.staticReportName || '',
                description: action.payload.item?.description || '',
                query: action.payload.item?.query || '',
                templateIds: action.payload.item?.templateIds || [],
                global: action.payload.item?.taskId === null || false,
                ...action.payload.item
            };
            state.editReportModal.templates = action.payload.templates || [];
        },
        closeReportEditModal(state) {
            state.editReportModal = { ...initialState.editReportModal };
        },
        changeReportEditModalFields(state, action: PayloadAction<Partial<StaticReport>>) {
            state.editReportModal.item = {
                ...state.editReportModal.item,
                ...action.payload,
            };
        },
        openTemplateEditModal(
            state,
            action: PayloadAction<{ title: string; item?: Partial<Template>; }>
        ) {
            state.editTemplateModal.open = true;
            state.editTemplateModal.title = action.payload.title;
            state.editTemplateModal.item = {
                templateName: action.payload.item?.templateName || '',
                description: action.payload.item?.description || '',
                isGlobal: action.payload.item?.isGlobal || false,
                ...action.payload.item
            }
        },
        closeTemplateEditModal(state) {
            state.editTemplateModal = { ...initialState.editTemplateModal };
        },
        changeTemplateEditModalFields(state, action: PayloadAction<Partial<Template>>) {
            state.editTemplateModal.item = {
                ...state.editTemplateModal.item,
                ...action.payload,
            };
        },
        resetScreen() {
            return initialState;
        },
    },
});

export const actions = staticReportsSnowflakeSlice.actions;
export default staticReportsSnowflakeSlice.reducer;
