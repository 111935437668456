import { dashboardMgmtService, lookupSvc, dashboardsService, dashboardsTableService, snowflakeService } from "services/serviceStorage";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { wrapRequest } from "common/utils";
import { BaseApiResponse } from 'common/types';
import {
    GetEmbedUrlParams,
    ReleaseGroup,
    CustomerAccount,
    UpdateDashboard,
    FetchTablesList,
    CopyDashboardRequest,
    ShareDashboards,
    Table
} from './types';
import { Dashboard } from 'store/dashboardViewer/types'

export const getAdminEmbedUrl = createAsyncThunk<string>(
    'dashboardMgmt/getAdminEmbedUrl',
    async () => {
        const { data } = await wrapRequest<BaseApiResponse<string>>({
            handler: dashboardMgmtService.getAdminEmbedUrl,
            showNotifications: true
        });

        return data!.result;
    }
);

export const getEmbedUrl = createAsyncThunk<string, GetEmbedUrlParams>(
    'dashboardMgmt/getEmbedUrl',
    async ({ taskId, dashboardId }) => {
        const { data } = await wrapRequest<BaseApiResponse<string>>({
            handler: () => dashboardMgmtService.getDashboardEmbedUrl(taskId, dashboardId),
            showNotifications: true
        });

        return data!.result;
    }
);

export const getReleaseGroups = createAsyncThunk<ReleaseGroup[]>(
    'dashboardMgmt/getReleaseGroups',
    async () => {
        const { data } = await wrapRequest<BaseApiResponse<ReleaseGroup[]>>({
            handler: lookupSvc.getTaskReleaseGroups,
            showNotifications: true
        });

        return data!.result;
    }
);

export const getCustomerAccounts = createAsyncThunk<CustomerAccount[]>(
    'dashboardMgmt/getCustomerAccounts',
    async () => {
        const { data } = await wrapRequest<BaseApiResponse<CustomerAccount[]>>({
            handler: lookupSvc.getCustomerAccounts,
            showNotifications: true
        });

        return data!.result;
    }
);

export const getSharedDashboards = createAsyncThunk<ShareDashboards[], string[]>(
    'dashboardMgmt/getSharedDashboards',
    async (did) => {
        const { data } = await wrapRequest<BaseApiResponse<ShareDashboards[]>>({
            handler: () => dashboardsService.getSharedDashboards({ did }),
            showNotifications: true
        });

        return data!.result;
    }
);

export const onShareDashboards = createAsyncThunk<void, ShareDashboards[]>(
    'dashboardMgmt/onShareDashboards',
    async (links, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => dashboardsService.shareDashboard(links),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);

export const onUnShareDashboards = createAsyncThunk<void, ShareDashboards[]>(
    'dashboardMgmt/onUnShareDashboards',
    async (links, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => dashboardsService.unshareDashboard(links),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);

export const getDashboard = createAsyncThunk<Dashboard, string>(
    'dashboardMgmt/getDashboard',
    async (dashboardId) => {
        const { data } = await wrapRequest<BaseApiResponse<Dashboard>>({
            handler: () => dashboardsService.getDashboard(dashboardId),
            showNotifications: true
        });

        return data!.result;
    }
);

export const updateDashboard = createAsyncThunk<void, UpdateDashboard>(
    'dashboardMgmt/updateDashboard',
    async (dashboardData, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => dashboardsService.updateDashboard(dashboardData),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);

export const createDashboard = createAsyncThunk<void, UpdateDashboard>(
    'dashboardMgmt/createDashboard',
    async (dashboardData, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => dashboardsService.createDashboard(dashboardData),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);

export const fetchTablesList = createAsyncThunk<{ items: Table[]; totalCount: number; }, FetchTablesList>(
    'dashboardMgmt/fetchTablesList',
    async ({ filter }) => {
        const { data } = await wrapRequest<BaseApiResponse<{ items: Table[]; totalCount: number; }>>({
            handler: () => dashboardsTableService.getDashboardTableList(filter),
            showNotifications: true
        });

        return data!.result;
    }
);

export const getTable = createAsyncThunk<Table, string>(
    'dashboardMgmt/getTable',
    async (tableId) => {
        const { data } = await wrapRequest<BaseApiResponse<Table>>({
            handler: () => dashboardsTableService.getDashboardTable(tableId),
            showNotifications: true
        });

        return data!.result;
    }
);

export const createTable = createAsyncThunk<void, Partial<Table>>(
    'dashboardMgmt/createTable',
    async (dashboardTable, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => dashboardsTableService.createDashboardTable(dashboardTable),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);

export const updateTable = createAsyncThunk<void, Partial<Table>>(
    'dashboardMgmt/updateTable',
    async (dashboardTable, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => dashboardsTableService.updateDashboardTable(dashboardTable),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);


export const generateInitialDb = createAsyncThunk<void, number>(
    'dashboardMgmt/generateInitialDb',
    async (releaseGroupId, { rejectWithValue }) => {
        const errs = await wrapRequest<BaseApiResponse<void>>({
            handler: () => snowflakeService.generateInitialDb(releaseGroupId),
            showNotifications: true
        });

        if (errs.errors?.length || errs.warnings?.length || errs.infos?.length) {
            return rejectWithValue(errs);
        }
    }
);

export const getConfiguration = createAsyncThunk<{ appUrl: string }>(
    'dashboardMgmt/getConfiguration',
    async () => {
        const { data } = await wrapRequest<BaseApiResponse<{ appUrl: string }>>({
            handler: snowflakeService.configuration,
            showNotifications: true
        });

        return data!.result;
    }
);

export const copyDashboards = createAsyncThunk<string, CopyDashboardRequest>(
    'dashboardMgmt/copyDashboards',
    async (request) => {
        const { data } = await wrapRequest<BaseApiResponse<string>>({
            handler: () => dashboardsService.copyDashboards(request),
            showNotifications: true
        });

        return data!.result;
    }
);