import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortingDirection } from "common/enums";
import {
    ScenarioStatusState,
    ScenarioStatus,
    ScenarioStatusFilter
} from "./types";
import { Scenario } from "common/types";

const initialState: ScenarioStatusState = {
    showGridSpinner: false,
    selectedIds: [],
    scenario: null,
    adjoinScenariosIds: [],
    gridSource: {
        data: [],
        total: 0,
        filter: {
            searchString: null,
            includeStatus: true,
            pageNumber: 1,
            pageSize: 30,
            orderByColumn: "segment",
            sortingDirection: sortingDirection.asc
        },
    },
};


export const scenarioStatusSlice = createSlice({
    name: "scenarioStatus",
    initialState,
    reducers: {
        setGridSource(state, action: PayloadAction<{ data: ScenarioStatus[]; total: number; filter?: ScenarioStatusFilter }>) {
            state.gridSource.data = action.payload.data;
            state.gridSource.total = action.payload.total;
            state.gridSource.filter = action.payload.filter ? action.payload.filter : state.gridSource.filter;
        },
        changeFilter(state, action: PayloadAction<Partial<ScenarioStatusFilter>>) {
            state.gridSource.filter = { ...state.gridSource.filter, ...action.payload }
        },
        changeSelected(state, action: PayloadAction<number[]>) {
            state.selectedIds = action.payload;
        },
        toggleGridSpinner(state, action: PayloadAction<boolean>) {
            state.showGridSpinner = action.payload;
        },
        setAdjoinScenarios(state, action: PayloadAction<number[]>) {
            state.adjoinScenariosIds = action.payload;
        },
        setScenario(state, action: PayloadAction<Scenario>) {
            state.scenario = action.payload;
        },
        reset(state) {
            // reset state except page size
            state = {
                ...initialState,
                gridSource: {
                    ...initialState.gridSource,
                    filter: {
                        ...initialState.gridSource.filter,
                        pageSize: state.gridSource.filter.pageSize,
                    },
                },
            };
        }
    }
});

export const actions = scenarioStatusSlice.actions;
export default scenarioStatusSlice.reducer;