import BaseService from './baseService';
import { CopyDashboardRequest } from 'store/dashboardMgmt/types';

export default class DashboardsService extends BaseService {
    constructor() {
        super('dashboards');
    }

    /**
    * Gets a dashboard by ID
    * @param {string} dashboardId - GUID of the dashboard
    * @returns Dashboard data
    */
    getDashboard = (dashboardId: string) => this.httpGetRequest(`${dashboardId}`);

    /**
    * Gets list of dashboards
    * @param {Object} filters - Filters for dashboards list
    * @param {Array<string>} filters.dashboardIds - Array of dashboard IDs (GUIDs)
    * @param {Array<number>} filters.releaseGroupIds - Array of release group IDs
    * @param {boolean} filters.isActive - Active status
    * @param {number} [filters.pageNumber] - Page number for pagination
    * @param {number} [filters.pageSize] - Page size for pagination
    * @param {string} [filters.searchString] - Page size for pagination
    * @returns Paginated list of dashboards
    */
    getDashboardsList = (filters: Partial<{
        dashboardIds: Array<string>;
        releaseGroupIds: Array<number>;
        isActive: boolean;
        pageNumber: number;
        pageSize: number;
        searchString: string;
    }> = {}) => {
        const queryString = this.generateQueryString({
            d: filters.dashboardIds,
            rg: filters.releaseGroupIds,
            a: filters.isActive,
            page: filters.pageNumber,
            size: filters.pageSize,
            ss: filters.searchString,
        });
        return this.httpGetRequest(`list?${queryString}`);
    }

    /**
     * Creates a new dashboard
     * @param {Object} dashboardData - Data for the new dashboard
     * @param {string} dashboardData.dashboardName - Dashboard Name
     * @param {string} dashboardData.description - Decription
     * @param {number} dashboardData.releaseGroupId - Release group ID
     * @param {Array<string>} dashboardData.tableIds - List of related table query ids
     * @returns GUID of the created dashboard
     */
    createDashboard = (dashboardData: Partial<{
        dashboardName: string;
        description: string;
        releaseGroupId: number;
        tableIds: string[]
    }>) => this.httpPostRequest('', dashboardData);

    /**
    * Updates an existing dashboard
    * @param {Object} dashboardData - Data for the new dashboard
    * @param {string} dashboardData.dashboardId - Dashboard ID
    * @param {string} dashboardData.dashboardName - Dashboard Name
    * @param {string} dashboardData.description - Decription
    * @param {number} dashboardData.releaseGroupId - Release group ID
    * @param {string} dashboardData.quicksightId - Quicksight source ID
    * @param {Array<string>} dashboardData.tableIds - List of related table query ids
    * @returns GUID of the updated dashboard
    */
    updateDashboard = (dashboardData: Partial<{
        dashboardId: string;
        dashboardName: string;
        releaseGroupId: number;
        quicksightId: string;
        tableIds: string[]
    }>) => this.httpPutRequest('', dashboardData);

    /**
     * Shares dashboard with other customers
     * @param {Array<Object>} links - Array of CustomerDashboardLinkContract
     * @param {number} links.accountId - Account identifier
     * @param {string} links.dashboardId - Dashboard identifier
     */
    shareDashboard = (links: Array<{
        accountId: number;
        dashboardId: string;
    }>) => this.httpPostRequest('share', links);

    /**
     * Unshares dashboard with other customers
     * @param {Array<Object>} links - Array of CustomerDashboardLinkContract
     * @param {number} links.accountId - Account identifier
     * @param {string} links.dashboardId - Dashboard identifier
     */
    unshareDashboard = (links: Array<{
        accountId: number;
        dashboardId: string;
    }>) => this.httpPostRequest('unshare', links);

    /**
     * Deletes dashboards
     * @param {Array<string>} dashboardIds - Array of dashboard IDs (GUIDs)
     */
    deleteDashboards = (dashboardIds: Array<string>) => this.httpDeleteRequest(`?${this.generateQueryString({ uid: dashboardIds })}`);

    /**
     * Gets list of shared dashboards
     * @param {Object} filters - Filters for shared dashboards
     * @param {Array<string>} filters.did - Array of dashboard IDs (GUIDs)
     * @param {Array<number>} filters.aid - Array of account IDs
     * @returns List of shared dashboard links
     */
    getSharedDashboards = (filters: Partial<{ did: Array<string>; aid: Array<number> }> = {}) => {
        const queryString = this.generateQueryString({
            did: filters.did,
            aid: filters.aid,
        });
        return this.httpGetRequest(`shared?${queryString}`);
    }

    /** 
    * Loads scenarios into dynamic tables for dashboards 
    * @param {string} dashboardId - Filters for Load statuses
    * @param {Object} filters - Filters for Load statuses
    * @param {Array<number>} filters.scenarioIds - Scenario ids
    * @param {Array<string>} filters.loadReportStates - States filter (RUNNING/ERROR/COMPLETED/NONE)
    * @param {Array<number>} filters.taskIds - Task ids filter
    * @param {number} filters.pageNumber - Page number filter
    * @param {number} filter.pageSize - Page size filter
    * @param {string} filter.searchString - searchString 
    * @returns Paged states list
    */
    getDashboardLoadState = (dashboardId: string, filters: Partial<{
        taskIds: Array<number>;
        scenarioIds: Array<number>;
        loadReportStates: Array<string>;
        pageNumber: number;
        pageSize: number;
        searchString: string;
    }> = {}) => {
        const queryString = this.generateQueryString({
            t: filters.taskIds,
            s: filters.scenarioIds,
            status: filters.loadReportStates,
            page: filters.pageNumber,
            size: filters.pageSize,
            ss: filters.searchString,
        });
        return this.httpGetRequest(`${dashboardId}/loaded-state?${queryString}`);
    }

    /** 
    * Loads scenarios into dynamic tables for dashboards  
    * @param {Object} filters - Filters for Load statuses
    * @param {Array<number>} filters.scenarioIds - Scenario ids
    * @param {Array<string>} filters.dashboardIds - Dashboard ids
    * @param {boolean} filters.force - If set to TRUE = reloads all dashboard tables
    */
    loadScenarios = (filters: Partial<{
        scenarioIds: Array<number>;
        dashboardIds: Array<string>;
        force: boolean;
    }> = {}) => {
        const queryString = this.generateQueryString({
            s: filters.scenarioIds,
            d: filters.dashboardIds,
            force: filters.force
        });
        return this.httpPostRequest(`load?${queryString}`);
    }

    /** 
    * Copy dashboard to other release group 
    * @param {Object} request - Request object
    * @param {Array<string>} request.qsDashboardIds	- Ids of dashboards
    * @param {number} request.releaseGroupId - Release Group Id
    */
    copyDashboards = (request: CopyDashboardRequest) => this.httpPostRequest('copy', request);
}
