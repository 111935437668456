import React from "react";
import { Redirect, Route } from "react-router";
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../config';

const MsalAuthRoute = props => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ ...loginRequest, forceRefresh: true }}
            errorComponent={({ error }) => <p>An Error Occurred: {error}</p>}
            loadingComponent={() => <p>Authentication in progress...</p>}
        >
            <Route {...props} />
        </MsalAuthenticationTemplate>
    )
}

export default MsalAuthRoute;

