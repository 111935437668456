import BaseService from './baseService';

export default class StaticReportsSnowflakeService extends BaseService {
    constructor() {
        super('staticReports');
    }

    /**
     * Gets the static reports.
     * @param {Object} filters - Filters for static reports
     * @param {<number>} filters.releaseGroupId - ReleaseGroupId
     * @param {<number>} filters.taskId - Task id
     */
    getStaticReports = (filters: { releaseGroupId: number; taskId: number; }) => this.httpPostRequest('getReports', filters);

    /**
    * Gets the static report.
    * @param {<number>} reportId - Report Id
    */
    getStaticReport = (reportId: number) => this.httpGetRequest(`getStaticReport/${reportId}`);

    /**
     * Gets histories of the static reports.
     * @param {Object} filters - Filters for static reports
     * @param {number} filters.taskId - Id of the task.
     * @param {boolean} filters.hasLinkedCases - Flag that indicates whether to include histories with or without cases.
     */
    getStaticReportHistories = (filters: { taskId: number; hasLinkedCases?: boolean }) => this.httpPostRequest('getStaticReportHistories', filters);

    /**
     * Gets scenarios.
     * @param {Object} filters - Filters for scenarios
     * @param {<number>} filters.taskId - Task id
     * @param {<number>} filters.pageNumber - Page Number
     * @param {<number>} filters.pageSize - Page Size
     * @param {string} filters.searchString - SearchString
     */
    getScenarios = (filters: Partial<{
        taskId: number;
        pageNumber: number;
        pageSize: number;
        searchString: string;
    }>) => this.httpPostRequest('getScenarios', filters);

    /**
     * Gets templates.
     * @param {Object} filters - Filters for templates
     * @param {<number>} filters.taskId - Task id
     * @param {<number>} filters.releaseGroupId - Release Group Id
     */
    getTemplates = (filters: { taskId: number; releaseGroupId: number }) => this.httpPostRequest('getTemplates', filters);

    /**
     * Gets template.
     * @param {<number>} templateId - Template Id.
     */
    getTemplate = (templateId: number) => this.httpGetRequest(`getTemplate/${templateId}`);

    /**
    * Loads scenario results into Snowflake.
    * @param {Array<number>} scenarioIds - scenario Ids
    */
    loadResults = (scenarioIds: number[]) => this.httpPostRequest("loadResults", scenarioIds);

    /**
    * Delete static reports.
    * @param {Array<number>} reportIds - report Ids
    */
    deleteStaticReports = (reportIds: number[]) => this.httpPostRequest('deleteStaticReports', { reportIds });

    /**
    * Delete templates. Saves scenarios
    * @param {Array<number>} templateIds - template Ids
    */
    deleteTemplates = (templateIds: number[]) => this.httpPostRequest('deleteTemplates', { templateIds });

    /**
    * Save static report.
    * @param {Object} report - Static report.
    * @param {<number>} report.staticReportId - Static Report Id.
    * @param {<string>} report.staticReportName -  Static Report Name.
    * @param {<string>} report.description - Static Report Description.
    * @param {<string>} report.query - Query.
    * @param {Array<number>} report.templateIds - Ids of templates linked with static report .
    * @param {<number> | null} report.taskId - Task Id | null - if selected global flag .
   */
    saveStaticReport = (report: Partial<{
        staticReportId: number;
        staticReportName: string;
        description: string;
        query: string;
        templateIds: number[];
        taskId: number | null
    }>) => this.httpPutRequest('saveStaticReport', report);

    /**
    * Save template(group).
    * @param {Object} template - Template.
    * @param {<number>} template.templateId - Template Id.
    * @param {<string>} template.templateName -  Template Name.
    * @param {<string>} template.description - Template Description.
    * @param {<boolean>} template.isGlobal - If isGlobal true, then taskId should be null.
    * @param {<number>} template.taskId - Task Id.
    */
    saveTemplate = (template: Partial<{
        templateId: number;
        templateName: string;
        description: string;
        isGlobal: boolean;
        taskId: number | null;
    }>) => this.httpPutRequest('saveTemplate', template);

    /**
    * Runs static reports for a scenario.
    * @param {Object} request - Request for runReportsForScenarios
    * @param {<number>} request.taskId - Task Id.
    * @param {Array<number>} request.staticReportIds - Ids of static reports.
    * @param {Array<number>} request.scenarioIds - Ids of scenarios.
    */
    runReportsForScenarios = (request: {
        taskId: number;
        staticReportIds: number[];
        scenarioIds: number[];
    }) => this.httpPostRequest("runReportsForScenarios", request);

    /**
    * Gets download urls for multiple query ids at ones.
    * @param {Array<string>} queryExecutionIds
    * @returns {Array<stirng>} - urls for download results
    */
    downloadResults = (queryExecutionIds: string[]) => this.httpPostRequest("getResultsUrls", queryExecutionIds);

    /**
    * Cancels the report execution
    * @param queryExecutionId 
    */
    cancelExecution = (queryExecutionId: string) => this.httpDeleteRequest(`cancel/${queryExecutionId}`);
}