export const linkActions = {
    none: 0,
    link: 1,
    unlink: 2
}

export const levels = {
    cube: 1,
    scenario: 2
};

export const partitionLevels = {
    reports: 1,
    segments: 2
}

export const detailTypes = {
    linked: 1,
    available: 2
}

export const scopes = {
    notUsed: 10,
    none: 1,
    hourly: 2,
    daily: 3
}

export const alertTypes = {
    error: 1,
    warning: 2,
    success: 3,
    info: 4
}

export const layoutTabs = {
    cubeManagement: 1,
    templateManagement: 2,
    staticReports: 3,
    psoResults: 4,
    dashboard: 5
};

export const staticReportLevels = {
    editor: 1,
    reportHistory: 2,
    savedReports: 3
}

export const otiManagementLevels = {
    history: 1,
    fileHistories: 2
}

export const cubeStates = {
    notExits: 0,
    scaling: 1,
    running: 2,
    stopped: 3,
    restoring: 4
}

export const staticReportStates = {
    none: "None",
    loadingResults: "LoadingResults",
    runningQuery: "RunningQuery",
    archiving: "Archiving",
    errors: "Errors",
    completed: "Completed",
    cancelled: "Cancelled"
};

export const psoResultsLevels = {
    cases: 1,
    files: 2
}

export const psoResultsState = {
    none: "None",
    merged: "Merged",
    readyForMerge: "ReadyForMerge",
    pending: "Pending",
    running: "Running",
    validated: "Validated"
};

export const dashboardScenarioStates = {
    none: "None",
    loading: "Loading",
    loaded: "Loaded"
};

export const dashboardTabs = {
    stackReport: 1,
    map: 2,
    comparer: 3
};

export const dashboardTypes = {
    stackReport: 1,
    mapReport: 2,
    comparerReport: 3,
};

export const stackReportAreaAgg = {
    avg: 1,
    max: 2,
};

export const stackReportUnitTypeModes = {
    p: 1,
    max: 2,
};

export const mapInjectorFields = {
    lmpAvg: 1,
    pCap: 2,
};

export const mapPeriods = {
    daily: 1,
    offpeak: 2,
    peak: 3
}

export const mapUnitTypeModes = {
    cap: 1,
    max: 2,
}

export const mapTabs = {
    lmpAvg: 1,
    pCap: 2,
    areas: 3
};

export const colors = {
    red: { r: 255, g: 0, b: 0, a: 1 },
    paleRed: { r: 255, g: 0, b: 0, a: 0.1 },
    green: { r: 0, g: 255, b: 0, a: 1 },
    paleGreen: { r: 0, g: 255, b: 0, a: 0.1 },
    yellow: { r: 255, g: 255, b: 0, a: 1 },
    blue: { r: 0, g: 0, b: 255, a: 1 }
};

export const fileStates = {
    unchanged: "Unchanged",
    modified: "Modified",
    missing: "Missing",
    new: "New"
};

export const rowStates = {
    unchanged: "Unchanged",
    modified: "Modified",
    missing: "Missing",
    new: "New"
};

export const scrollPosition = {
    top: 'top',
    down: 'down'
};

export const sortingDirection = {
    none: 0,
    asc: 1,
    desc: 2
}

export const tagFilterType = {
    tagValue: 0,
    scenario: 1,
    tagKey: 2,
    scenarioGroup: 3,
    psoState: 4
}

export const probeMasterTabs = {
    files: 1,
    history: 2
}

export const probeDetailTabs = {
    caseGroups: 1,
    status: 2
}

export const probeGroupActions = {
    add: 1,
    edit: 2
}

export const probeStatus = {
    none: 0,
    pending: 1,
    running: 2,
    error: 3,
    completed: 4
};

export const otiStatuses = {
    runningQuery: 0,
    loadingSource: 1,
    pending: 2,
    cancelled: 3,
    errors: 4,
    completed: 5,
    none: 6,
}

export const otiFilterType = {
    single: 0,
    multi: 1,
    areas: 2
}

export const scenarioGroupFilesTabs = {
    settings: 0,
    charge: 1,
    scenario1: 2,
    scenario2: 3,
    scheduled: 4,
    daily: 5,
}

export const scenarioGroupFileOperationTypes = {
    add: 0,
    change: 1,
    delete: 2,
    clear: 3,
}

export const scenarioGroupRowStatuses = {
    unchanged: 0,
    changed: 1,
    new: 2,
    hidden: 3,
}

export const psoStates = {
    systemError: "SystemError",
    notRun: "NotRun",
    pending: "Pending",
    running: "Running",
    completed: "Completed",
    cancelled: "Cancelled",
    error: "Error",
    spotInterrupt: "SpotInterrupt"
};

export const scenarioCubeStatuses = {
    loading: "Loading",
    pending: "Pending",
    partial: "Partial",
    loaded: "Loaded"
};

export const uiPages = {
    scenarioGroup: 1,
    scenario: 2
};

export const gridParams = {
    mainQuery: 'filter',
    pageNumber: 'pageNumber',
    pageSize: 'pageSize',
    orderByColumn: 'orderByColumn',
    sortingDirection: 'sortingDirection',
    search: 'search',
    pageFilter: 'pageFilter',
    loadReportStates: 'loadReportStates',
    scenarioTypes: 'scenarioTypes',
    resourceAdequacyGroupIds: 'resourceAdequacyGroupIds',
    scenarioIds: 'scenarioIds',
    dashboardLoadStatuses: 'dashboardLoadStatuses',
    snowflakeRunStatuses: 'snowflakeRunStatuses'
};

export const showAllBtnText = {
    show: 'View All Results',
    hide: 'Hide All Results',
};

export const billingParams = {
    storageName: 'billingParams'
}

export const scenarioReportQueueType = {
    load: 0,
    delete: 1
};

export const loadReportStates = {
    none: "None",
    completed: "Completed",
    partial: "Partial",
    running: "Running",
    pending: "Pending",
    deleted: "Deleted",
    error: "Error"
};

export const specialErrorStatuses = {
    memoryError: 'MemoryError',
    systemError: 'SystemError',
    machineUnresponsiveError: 'MachineUnresponsiveError',
    stallError: 'StallError',
    spotInterruptionError: 'SpotInterruptionError',
    integerSolution: 'Integer solution'
}

export const resourceAdequacyStatuses = {
    completed: 1,
    pending: 2,
    running: 3,
    failed: 4,
    cancelled: 5,
    completedNoSecondStage: 6,
    unloaded: 7,
}

export const resourceAdequacyMode = {
    snap: 1,
    standard: 2,
}

export const scenarioTypes = {
    nonRA: 0,
    RA: 1,
    allCases: [0, 1]
}

export const archivedFilterStates = {
    active: "active",
    pendingDelete: "on_hold",
    archived: "archived",
}

export const archivingType = {
    inputsOnly: "inputs-only",
    inputsAndResults: "inputs-and-results",
}

export const dashboardLoadStatuses = {
    none: "None",
    inProgress: "InProgress",
    completed: "Completed",
    failed: "Failed",
    unloaded: "Unloaded",
}

export const dashboardListType = {
    folder: "Folder",
    dashboard: "Dashboard"
}

export const databaseTableType = {
    baseTable: 'BASE TABLE',
    view: 'VIEW',
    dashboard: 'DASHBOARD TABLE'
}

export const athenaHistoriesStatuses = {
    queued: 'QUEUED',
    running: 'RUNNING',
    succeeded: 'SUCCEEDED',
    failed: 'FAILED',
    cancelled: 'CANCELLED'
}

export const notificationStatus = {
    none: 0,
    running: 1,
    completed: 2,
    error: 3
}

export const qsCasesLoadedStates = {
    none: "NONE",
    completed: "COMPLETED",
    running: "RUNNING",
    error: "ERROR",
    cancelled: "CANCELLED"
};