import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { archivedFilterStates } from "common/enums";
import {
    ProjectMgmtState,
    ProjectGridFilter
} from "./types";
import { Project } from "common/types";

const initialState: ProjectMgmtState = {
    showGridSpinner: false,
    modalHeader: "Edit project",
    gridSource: {
        data: [],
        total: 0,
        filter: {
            searchString: null,
            pageNumber: 1,
            pageSize: 30,
            activeStates: [archivedFilterStates.active],
        },
    },
    deleteModal: {
        open: false,
        projectId: 0,
        projectName: '',
    },
    archiveModal: {
        open: false,
        projectId: 0,
        projectName: '',
        item: {
            archiveResults: 1,
            archiveDays: null,
        },
    },
    editModal: {
        isOpen: false,
        spinner: false,
        source: null,
    }
};

const projectMgmtSlice = createSlice({
    name: "projectManagement",
    initialState,
    reducers: {
        setGridSource: (state, action: PayloadAction<{ data: Project[]; total: number; filter?: ProjectGridFilter }>) => {
            state.gridSource.data = action.payload.data;
            state.gridSource.total = action.payload.total;
            state.gridSource.filter = action.payload.filter ? action.payload.filter : state.gridSource.filter;
        },
        changeFilter: (state, action: PayloadAction<Partial<ProjectGridFilter>>) => {
            state.gridSource.filter = { ...state.gridSource.filter, ...action.payload }
        },
        toggleGridSpinner: (state, action: PayloadAction<boolean>) => {
            state.showGridSpinner = action.payload;
        },
        setModalHeader: (state, action: PayloadAction<string>) => {
            state.modalHeader = action.payload;
        },
        openDeleteModal: (state, action: PayloadAction<{ projectId: number; projectName: string }>) => {
            state.deleteModal = { open: true, projectId: action.payload.projectId, projectName: action.payload.projectName };
        },
        closeDeleteModal: (state) => {
            state.deleteModal = initialState.deleteModal;
        },
        openArchiveModal: (state, action: PayloadAction<{ projectId: number; projectName: string }>) => {
            state.archiveModal = {
                open: true,
                projectId: action.payload.projectId,
                projectName: action.payload.projectName,
                item: state.archiveModal.item
            };
        },
        closeArchiveModal: (state) => {
            state.archiveModal = initialState.archiveModal;
        },
        changeArchiveModalFields: (state, action) => {
            state.archiveModal.item = {
                ...state.archiveModal.item,
                ...action.payload,
            };
        },
        openEditModal: (state, action: PayloadAction<Project | undefined>) => {
            state.editModal = { ...state.editModal, isOpen: true, source: action.payload ? action.payload : state.editModal.source }
        },
        closeEditModal: (state) => {
            state.editModal = initialState.editModal;
        },
        toggleEditModalSpinner: (state, action: PayloadAction<boolean>) => {
            state.editModal.spinner = action.payload
        },
        resetGridData: (state) => {
            state.gridSource.data = [];
            state.gridSource.total = 0;
            state.gridSource.filter = {
                ...state.gridSource.filter,
                pageNumber: 1,
                activeStates: [],
            };
        },
        resetState(state) {
            // reset state except page size
            state = {
                ...initialState,
                gridSource: {
                    ...initialState.gridSource,
                    filter: {
                        ...initialState.gridSource.filter,
                        pageSize: state.gridSource.filter.pageSize,
                    },
                },
            };
        }
    },
});

export const actions = projectMgmtSlice.actions;
export default projectMgmtSlice.reducer;

