import { fetch } from "common/helpers";
import { scenarioStatusSvc as service, scenarioSvc as scenarioMgmtSvc } from "services/serviceStorage";
import moment from "moment";
import {
    ScenarioStatus,
    FetchStatusesParams,
    ManualErrorParams,
    GetLogsParams,
    GetErrorLogsParams,
    GetResultsParams,
    ForceMergeParams,
    RestartSegmentParams,
    StopSegmentParams,
    RestartFailedSegmentsParams,
    GetAdjoinScenariosParams,
    FetchScenarioParams
} from './types';
import { BaseApiResponse, Scenario } from "common/types";

export const fetchStatuses = ({ filter, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchStatusesParams) =>
    fetch({
        spinnerToggle,
        callback: (r: BaseApiResponse<{ items: ScenarioStatus[]; totalCount: number }>) =>
            successCallback({
                data: r.result.items.map(i => ({
                    ...i,
                    key: i.historyId,
                    segmentStart: i.segmentStart && moment.utc(i.segmentStart).format("MM-DD-YYYY HH:mm"),
                    segmentEnd: i.segmentEnd && moment.utc(i.segmentEnd).format("MM-DD-YYYY HH:mm"),
                    startWork: i.startWork && moment.utc(i.startWork).local().format("MM-DD-YYYY HH:mm"),
                    psoTime: typeof i.psoTime === 'number' && i.psoTime > 0 ? moment.duration(i.psoTime, "seconds").format("hh:mm:ss", { trim: false }) : '',
                    vmTime: typeof i.vmTime === 'number' && i.vmTime > 0 ? moment.duration(i.vmTime, "seconds").format("hh:mm:ss", { trim: false }) : '',
                    errors: i.statusErrors?.map(e => ({
                        status: e.status,
                        startWork: e.startWork,
                        date: e.startWork && moment.utc(e.startWork).local().format("MM-DD-YYYY HH:mm"),
                        friendlyMessage: e.friendlyMessage,
                        message: e.friendlyMessage,
                        hasLogs: e.hasLogs,
                        historyId: e.historyId
                    })),
                })),
                total: r.result.totalCount,
            }),
        request: () => service.getStatuses(filter),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const manualError = ({ scenarioId, segmentIds, spinnerToggle, successCallback, errorCallback, validationCallback }: ManualErrorParams) =>
    fetch({
        spinnerToggle,
        request: () => service.manualError({ scenarioId, segmentIds }),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const getLogs = ({ historyId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetLogsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.getLogs(historyId),
        callback: (r: BaseApiResponse<string>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const getErrorLogs = ({ historyId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetErrorLogsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.getErrorLogs(historyId),
        callback: (r: BaseApiResponse<string>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const getResults = ({ historyId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetResultsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.getResults(historyId),
        callback: (r: BaseApiResponse<string[]>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const forceMerge = ({ scenarioId, spinnerToggle, successCallback, errorCallback, validationCallback }: ForceMergeParams) =>
    fetch({
        spinnerToggle,
        request: () => scenarioMgmtSvc.runForceMerge([scenarioId]),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const restartSegment = ({ historyId, spinnerToggle, successCallback, errorCallback, validationCallback }: RestartSegmentParams) =>
    fetch({
        spinnerToggle,
        request: () => service.restartSegment(historyId),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const stopSegment = ({ historyId, spinnerToggle, successCallback, errorCallback, validationCallback }: StopSegmentParams) =>
    fetch({
        spinnerToggle,
        request: () => service.stopSegment(historyId),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const restartFailedSegments = ({ scenarioId, spinnerToggle, successCallback, errorCallback, validationCallback }: RestartFailedSegmentsParams) =>
    fetch({
        spinnerToggle,
        request: () => service.restartFailedSegments(scenarioId),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const getAdjoinScenarios = ({ scenarioId, spinnerToggle, successCallback, errorCallback, validationCallback }: GetAdjoinScenariosParams) =>
    fetch({
        spinnerToggle,
        request: () => service.getAdjoinScenarios(scenarioId),
        callback: (r: BaseApiResponse<number[]>) => successCallback(r.result),
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const fetchScenario = ({ scenarioId, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchScenarioParams) =>
    fetch({
        spinnerToggle,
        request: () => scenarioMgmtSvc.getScenario(scenarioId),
        callback: (r: BaseApiResponse<Scenario>) => successCallback({
            ...r.result,
            useSpot: r.result.useSpot != null ? (r.result.useSpot ? 1 : 0) : null  // convert nullable boolean to int for UI
        }),
        validationCallback: validationCallback,
        errorCallback: errorCallback,
    });