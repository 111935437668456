import { fetch, formatTimeSpan, mapLookup, displayErrors } from "common/helpers";
import {
    taskSvc as service,
    lookupSvc,
    dashboardsService as dshbSvc,
    dashboardGroupsService as dshbGrSvc,
} from "services/serviceStorage";
import moment from "moment";
import {
    FetchTaskEditLookupsParams,
    TaskCopyLookups,
    FetchTasksParams,
    FetchTaskParam,
    DatasetConvert,
    DashboardsLookup,
    SaveMarketModel,
    ReleaseLookupItem,
    MarketModelLookupItem,
    TaskEditLookups,
    ReportTemplateLookupItem,
    TaskCopyModalSource,
} from './types';
import { LookupItem, BaseApiResponse, Task } from "common/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dashboard, DashboardGroup } from "store/dashboardViewer/types";
import { wrapRequest } from "common/utils";

export const fetchTaskEditLookups = createAsyncThunk<TaskEditLookups, FetchTaskEditLookupsParams>(
    "taskMgmt/fetchTaskEditLookups",
    async ({ taskId, releaseId, releaseGroupId, allowChangeRelease }, { rejectWithValue }) => {
        try {
            const requests = [
                lookupSvc.getPartitioningModes(),
                lookupSvc.getTimeUnits(),
                lookupSvc.getReleases(allowChangeRelease ? undefined : taskId),
                lookupSvc.getMarketModels(releaseId || ""),
                lookupSvc.getCubeTemplates(taskId || ""),
                lookupSvc.getReportTemplates(taskId || ""),
                lookupSvc.getVMGroups(),
                service.getLeadsLookup(),
            ];

            if (releaseGroupId) {
                requests.push(
                    dshbSvc.getDashboardsList({ releaseGroupIds: [releaseGroupId] }),
                    dshbGrSvc.getDashboardGroupsList({ releaseGroupIds: [releaseGroupId] })
                );
            }

            const [
                partitioningRes,
                timeUnitRes,
                releasesRes,
                marketModelsRes,
                cubeTemplatesRes,
                reportTemplatesRes,
                vmGroupsRes,
                leadsRes,
                dashboardsRes,
                dashboardGroupsRes
            ] = await Promise.all(requests);

            return {
                partitioning: partitioningRes.data.result.map(mapLookup),
                timeUnits: timeUnitRes.data.result.map(mapLookup),
                releases: releasesRes.data.result.map((r: ReleaseLookupItem) => ({
                    ...r,
                    key: r.id,
                    displayName: r.displayName,
                    releaseGroupId: r.releaseGroupId,
                })),
                marketModels: marketModelsRes.data.result.map(mapLookup),
                cubeTemplates: cubeTemplatesRes.data.result.map(mapLookup),
                reportTemplates: reportTemplatesRes.data.result.map((r: ReportTemplateLookupItem) => ({
                    ...r,
                    key: r.id,
                    displayName: r.displayName,
                    releaseGroupId: r.releaseGroupId,
                    isGlobal: r.isGlobal,
                })),
                vmGroups: vmGroupsRes.data.result.map(mapLookup),
                leads: leadsRes.data.result.map(mapLookup),
                dashboards: dashboardsRes?.data.result.items.map((d: Dashboard) => ({
                    value: d.dashboardId,
                    label: d.dashboardName,
                })) ?? [],
                dashboardGroups: dashboardGroupsRes?.data.result.items.map((g: DashboardGroup) => ({
                    value: g.groupId,
                    label: g.groupName,
                    linkedIds: g.dashboards.map(d => d.id),
                })) ?? [],
            };
        } catch (error) {
            displayErrors(["Internal server error. Please, contact system administrator."]);
            return rejectWithValue(error);
        }
    }
);

export const fetchTaskCopyLookups = createAsyncThunk<TaskCopyLookups, { [key: string]: number }>(
    "taskMgmt/fetchTaskCopyLookups",
    async ({ taskId, releaseGroupId }, { rejectWithValue }) => {
        try {
            const [projectsRes, scenariosRes, releasesRes, marketModelsRes, releaseGroupsRes] = await Promise.all([
                lookupSvc.getProjects(),
                service.getScenariosLookup(taskId),
                lookupSvc.getReleases(),
                lookupSvc.getMarketModelsV2(),
                lookupSvc.getTaskReleaseGroups(releaseGroupId)
            ]);

            return {
                projects: projectsRes.data.result.map(mapLookup),
                scenarios: scenariosRes.data.result.map((r: LookupItem) => ({
                    idx: r.id,
                    displayName: `${r.id} ${r.displayName}`,
                })),
                releases: releasesRes.data.result.map((r: ReleaseLookupItem) => ({
                    ...r,
                    key: r.id,
                    id: r.id,
                    displayName: r.displayName,
                    releaseGroupId: r.releaseGroupId,
                })),
                marketModels: marketModelsRes.data.result.items.map((mm: MarketModelLookupItem) => ({
                    key: mm.id,
                    id: mm.id,
                    displayName: mm.name,
                })),
                releaseGroups: releaseGroupsRes.data.result,
            };
        } catch (error) {
            displayErrors(["Internal server error. Please, contact system administrator."]);
            return rejectWithValue(error)
        }
    }
);

export const fetchTasks = ({ filter, spinnerToggle, successCallback, validationCallback, errorCallback }: FetchTasksParams) =>
    fetch({
        spinnerToggle,
        callback: (r: BaseApiResponse<{ items: Task[]; totalCount: number }>) =>
            successCallback({
                data: r.result.items.map(t => ({
                    ...t,
                    key: t.taskId,
                    taskId: t.taskId,
                    taskName: t.taskName,
                    psoTime: typeof t.psoTime === 'number' && t.psoTime > 0 ? moment.duration(t.psoTime, "seconds").format("d[d] hh:mm:ss", { trim: false }) : '',
                    vmTime: typeof t.vmTime === 'number' && t.vmTime > 0 ? moment.duration(t.vmTime, "seconds").format("d[d] hh:mm:ss", { trim: false }) : '',
                    leadName: t.leadName,
                    isActive: t.isActive,
                    lastRunDate: t.lastRunDate && moment.utc(t.lastRunDate).local().format("MM-DD-YYYY HH:mm"),
                    marketModel: t.marketModel,
                    instanceId: t.instanceId,
                    archiveType: t.archiveType,
                    archiveDays: t.archiveDays,
                    activeState: t.activeState,
                    activeStateNextChangePeriod: formatTimeSpan(t.activeStateNextChangePeriod),
                    versionId: t.versionId,
                })),
                total: r.result.totalCount,
                filter: filter
            }),
        request: () => service.getTasks(filter),
        validationCallback: validationCallback,
        errorCallback: errorCallback
    });

export const fetchTask = ({ taskId, spinnerToggle, successCallback, errorCallback, validationCallback }: FetchTaskParam) =>
    fetch({
        spinnerToggle,
        request: () => service.getTask(taskId),
        callback: (r: BaseApiResponse<Task>) => successCallback(r.result),
        errorCallback,
        validationCallback
    });

export const deleteTask = createAsyncThunk<void, number>(
    'taskMgmt/deleteTask',
    async (taskId, { rejectWithValue }) => {
        const err = await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.deleteTask(taskId),
            showNotifications: true
        });

        if (err.errors?.length || err.warnings?.length) {
            return rejectWithValue(err);
        }
    }
);

export const forceDeleteTask = createAsyncThunk<void, number>(
    'taskMgmt/forceDeleteTask',
    async (taskId) => {
        await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.forceDeleteTask(taskId),
            showNotifications: true
        });
    }
);

export const saveTask = createAsyncThunk<void, Task>(
    'taskMgmt/saveTask',
    async (task, { rejectWithValue }) => {
        const err = await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.putTask({
                ...task,
                startDate: task.startDate && moment(task.startDate).format('YYYY-MM-DDTHH:mm:ss'), // Ignore timezone offset and send local date as UTC+0
                endDate: task.endDate && moment(task.endDate).format('YYYY-MM-DDTHH:mm:ss'),
                minDate: task.minDate && moment(task.minDate).format('YYYY-MM-DDTHH:mm:ss'),
                maxDate: task.maxDate && moment(task.maxDate).format('YYYY-MM-DDTHH:mm:ss'),
            }),
            showNotifications: true
        });

        if (err.errors?.length || err.warnings?.length) {
            return rejectWithValue(err);
        }
    }
);

export const archiveTask = createAsyncThunk<void, number>(
    'taskMgmt/archiveTask',
    async (taskId, { rejectWithValue }) => {
        const err = await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.archiveTask(taskId),
            showNotifications: true
        });

        if (err.errors?.length || err.warnings?.length) {
            return rejectWithValue(err);
        }
    }
);

export const copyTask = createAsyncThunk<void, TaskCopyModalSource>(
    'taskMgmt/copyTask',
    async (task, { rejectWithValue }) => {
        const err = await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.copyTask(task),
            showNotifications: true
        });

        if (err.errors?.length || err.warnings?.length) {
            return rejectWithValue(err);
        }
    }
);

export const datasetConvert = ({ task, spinnerToggle, successCallback, errorCallback, validationCallback }: DatasetConvert) =>
    fetch({
        spinnerToggle,
        request: () => service.datasetConvert(task),
        callback: successCallback,
        errorCallback: errorCallback,
        validationCallback: validationCallback
    });

export const initializeDb = createAsyncThunk<void, number>(
    'taskMgmt/initializeDb',
    async (taskId) => {
        await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.initDb(taskId),
            showNotifications: true
        });
    }
);

export const restoreDb = createAsyncThunk<void, number>(
    'taskMgmt/restoreDb',
    async (taskId) => {
        await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.restoreDb(taskId),
            showNotifications: true
        });
    }
);

export const archiveDb = createAsyncThunk<void, number>(
    'taskMgmt/archiveDb',
    async (taskId) => {
        await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.archiveDb(taskId),
            showNotifications: true
        });
    }
);

export const updateDUPSRelease = createAsyncThunk<void, number>(
    'taskMgmt/updateDUPSRelease',
    async (taskId) => {
        await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.updateDUPSRelease(taskId),
            showNotifications: true
        });
    }
);

export const fetchDashboardLookups = createAsyncThunk<DashboardsLookup, number>(
    "taskMgmt/fetchDashboardLookups",
    async (releaseGroupId, { rejectWithValue }) => {
        try {
            const [dashboardsRes, dashboardGroupsRes] = await Promise.all([
                dshbSvc.getDashboardsList({ releaseGroupIds: [releaseGroupId] }),
                dshbGrSvc.getDashboardGroupsList({ releaseGroupIds: [releaseGroupId] }),
            ]);

            return {
                dashboards: dashboardsRes?.data.result.items.map((d: Dashboard) => ({
                    value: d.dashboardId,
                    label: d.dashboardName,
                })) ?? [],
                dashboardGroups: dashboardGroupsRes?.data.result.items.map((g: DashboardGroup) => ({
                    value: g.groupId,
                    label: g.groupName,
                    linkedIds: g.dashboards.map(d => d.id),
                })) ?? [],
            };
        } catch (error) {
            displayErrors(["Internal server error. Please, contact system administrator."]);
            return rejectWithValue(error)
        }
    }
);

export const saveMarketModel = createAsyncThunk<void, SaveMarketModel>(
    'taskMgmt/updateDUPSRelease',
    async (model, { rejectWithValue }) => {
        const err = await wrapRequest<BaseApiResponse<void>>({
            handler: () => service.createMarketModel(model),
            showNotifications: true
        });

        if (err.errors?.length || err.warnings?.length) {
            return rejectWithValue(err);
        }
    }
);