const config = {
    msal: {
        clientId: process.env.MSAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.MSAL_TENANT_ID}`,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        scopes: [`api://${process.env.MSAL_CLIENT_ID}/User.Read`],
        pkce: true,
        disableHttpsCheck: false,
    },
    API_SERVER: process.env.API_SERVER,
    AUTH_SERVICE_URL: process.env.AUTH_SERVICE_URL
};

export const loginRequest = {
    scopes: [`api://${process.env.MSAL_CLIENT_ID}/User.Read`],
};

export const isMsal = Boolean(process.env.MSAL_CLIENT_ID);

export default config;
