import { combineReducers } from "redux";
import cubes from "./cubeReducer";
import layout from "./layoutReducer";
import cubeGroups from "./cubeGroupsReducer";
import staticReports from "./staticReportsReducer";
import staticReportsSnowflakeSlice from "store/staticReportsSnowflake";
import psoResults from "./psoResultsReducer";
import navigation from "./navigationReducer";
import dashboard from "./dashboardReducer";
import map from "./mapReducer";
import comparer from "./comparerReducer";
import ThemeOptions from "./ThemeOptions";
import instanceMgr from "./instanceManagerReducer";
import accountMgr from "./accountManagerReducer";
import projectMgmtSlice from "store/projectMgmt";
import taskMgmtSlice from "store/taskMgmt";
import scenarioMgmtSlice from "store/scenarioMgmt";
import scenarioStatusSlice from "store/scenarioStatus";
import reportingRoot from "./reportingReducer";
import enelytixBillingRoot from "./enelytixBillingReducer";
import logs from "./psoLogsReducer";
import scenarioGroups from "./scenarioGroupReducer";
import tags from "./tagReducer";
import otiManager from "./otiReducer";
import probes from "./probeReducer";
import resourceAdequacy from "./resourceAdequacyReducer";
import dashboardViewerSlice from "../store/dashboardViewer/reducer";
import dashboardMgmtSlice from "store/dashboardMgmt";
import oneLineDiagram from "./oneLineDiagramReducer";

const rootReducer = () =>
    combineReducers({
        cubes,
        layout,
        cubeGroups,
        staticReports,
        staticReportsSnowflakeSlice,
        navigation,
        psoResults,
        dashboard,
        map,
        comparer,
        ThemeOptions,
        instanceMgr,
        accountMgr,
        projectMgmtSlice,
        taskMgmtSlice,
        scenarioMgmtSlice,
        scenarioStatusSlice,
        reportingRoot,
        enelytixBillingRoot,
        logs,
        scenarioGroups,
        tags,
        probes,
        otiManager,
        resourceAdequacy,
        dashboardViewerSlice,
        dashboardMgmtSlice,
        oneLineDiagram,
    });

export default rootReducer;
