import React, { useState } from 'react';
import Select from 'components/common/Select';
import {
    Button,
    Form,
    Label,
    Input,
    Row,
    FormText,
    ModalHeader,
    ModalBody,
    Alert
} from 'reactstrap';
import Multiselect from 'components/common/Multiselect';
import DraggableModal from "common/DraggableModal";
import uniqueId from 'lodash/uniqueId';
import BlockUi from 'react-block-ui';
import { ScaleLoader as Loader } from "react-spinners";


const ModalFooter = props => {
    const { onSubmit, onCancel } = props;

    return (
        <div className="d-flex justify-content-between p-2">
            <Button color="link" onClick={onCancel}>Close</Button>{' '}
            <Button color="primary" className="btn-link-dark mr-3" onClick={onSubmit}>Save changes</Button>
        </div>
    )
}

const AccountEditModal = props => {
    const { open, header, locked, source, actions, error } = props;
    const { close, submit, changeState } = actions;
    const { projectCodeNames, userId, userName, password, email, projectIds, userRole, maxSegmentsPerRun, removeCubeLocksTimeUtc } = source;
    const { projects, projectCodes, userRoles } = source; // lookups

    const [validationErrors, setValidationErrors] = useState({});

    const validationRules = {
        name: [
            { validate: i => i.userName && i.userName !== "", errorMsg: `Name is required` },
        ],
        password: [
            { validate: i => i.userId || (i.password && i.password !== ''), errorMsg: `Password is required` },
        ],
        email: [
            { validate: i => i.email && i.email !== '', errorMsg: `Email is required` },
        ],
        role: [
            { validate: i => i.userRole && i.userRole !== '', errorMsg: `User role is required` },
        ],
        maxSegmentsPerRun: [
            { validate: i => i.maxSegmentsPerRun >= 1 && i.maxSegmentsPerRun <= 5000, errorMsg: `Max Segments Per Run must be from 1 to 5000` },
        ],
        removeCubeLocksTimeUtc: [
            { validate: i => i.removeCubeLocksTimeUtc >= 0 && i.removeCubeLocksTimeUtc <= 23, errorMsg: `Disconnect Cube Hours must be from 0 to 23` },
        ]
    };

    const validate = () => {
        const newErrors = {};
        Object.keys(validationRules).forEach(key => {
            validationRules[key].forEach(rule => {
                if (!rule.validate(source)) {
                    if (!newErrors[key]) {
                        newErrors[key] = rule.errorMsg;
                    }
                }
            });
        });

        setValidationErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validate()) {
            submit();
        }
    };

    const onClose = () => {
        setValidationErrors({});
        close();
    }

    const draggableId = `draggable-${uniqueId()}`;

    return (
        <DraggableModal isOpen={open} toggle={onClose} centered handle={`#${draggableId}`} backdrop="static" >
            <BlockUi tag="div" blocking={locked} loader={<Loader color={'var(--first)'} loading={locked} />}>
                <ModalHeader id={draggableId} toggle={onClose} className='move-cursor'>{header}</ModalHeader>
                <ModalBody className='no-cursor px-5' style={{ overflowY: 'inherit !important' }}>
                    <Form>
                        {error?.length > 0 && <Row className="my-2"><Alert color="danger">{error}</Alert></Row>}
                        <Row className="my-2">
                            <Label for="acc-form-userName">User name</Label>
                            <Input id="acc-form-userName" placeholder="User name" disabled={userId != null}
                                onChange={e => changeState({ userName: e.target.value })} value={userName || ''} />
                            {validationErrors.name && <FormText color="danger">{validationErrors.name}</FormText>}
                        </Row>
                        {!userId &&
                            <Row className="my-2">
                                <Label for="acc-form-password">Password</Label>
                                <Input type="password" id="acc-form-password" placeholder="Password"
                                    onChange={e => changeState({ password: e.target.value })} value={password || ''} />
                                {validationErrors.password && <FormText color="danger">{validationErrors.password}</FormText>}
                            </Row>}
                        <Row className="my-2">
                            <Label for="acc-form-email">Email</Label>
                            <Input type="email" id="acc-form-email" placeholder="example@email.com"
                                onChange={e => changeState({ email: e.target.value })} value={email || ''} />
                            {validationErrors.email && <FormText color="danger">{validationErrors.email}</FormText>}
                        </Row>
                        <Row className="my-2">
                            <Label for="acc-form-role">Role</Label>
                            <Select
                                id="acc-form-role"
                                defaultDisplay='Not selected'
                                className="w-100"
                                value={userRole}
                                onChange={idx => changeState({ userRole: idx })}
                                source={userRoles || []}>
                            </Select>
                            {validationErrors.role && <FormText color="danger">{validationErrors.role}</FormText>}
                        </Row>
                        <Row className="my-2">
                            <Label for="acc-form-projects">Projects</Label>
                            <Multiselect
                                id="acc-form-projects"
                                direction="up"
                                className="w-100"
                                source={projects}
                                selectedIds={projectIds}
                                onChange={e => changeState({ projectIds: e.value })} />
                        </Row>
                        <Row className="my-2">
                            <Label for="acc-form-pcodes">Project codes</Label>
                            <Multiselect
                                id="acc-form-pcodes"
                                direction="up"
                                className="w-100"
                                source={projectCodes}
                                selectedIds={projectCodeNames}
                                onChange={e => changeState({ projectCodeNames: e.value })} />
                        </Row>
                        <Row className="my-2">
                            <Label for="acc-form-maxSegmentsPerRun">Max Segments Per Run:</Label>
                            <Input type="number" id="acc-form-maxSegmentsPerRun" placeholder="Max Segments Per Run"
                                onChange={e => changeState({ maxSegmentsPerRun: e.target.value })} value={maxSegmentsPerRun}
                                min="1" max="5000" />
                            {validationErrors.maxSegmentsPerRun && <FormText color="danger">{validationErrors.maxSegmentsPerRun}</FormText>}
                        </Row>
                        <Row className="my-2">
                            <Label for="acc-form-removeCubeLocksTimeUtc">Disconnect Cube at Hour (0-23, local time):</Label>
                            <Input type="number" id="acc-form-removeCubeLocksTimeUtc" placeholder="Disconnect Cube at Hour (0-23, local time)"
                                onChange={e => changeState({ removeCubeLocksTimeUtc: e.target.value })} value={removeCubeLocksTimeUtc}
                                min="0" max="23" />
                            {validationErrors.removeCubeLocksTimeUtc && <FormText color="danger">{validationErrors.removeCubeLocksTimeUtc}</FormText>}
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter className="mb-5" onSubmit={handleSubmit} onCancel={onClose} />
            </BlockUi >
        </DraggableModal >
    )
}

export default AccountEditModal;