import { fetch } from "common/helpers";
import { lookupSvc, staticReportsSnowflakeSvc as reportSvc } from "services/serviceStorage";
import {
    GetStaticReportHistoriesParams,
    ReportHistoriesResponse,
    GetScenariosParams,
    GetScenariosResponse,
    GetTemplatesParams,
    GetTemplatesResponse,
    GetTemplateParams,
    GetTemplateResponse,
    FetchReportingLookup,
    FetchReportingLookupResponse,
    GetStaticReportsParams,
    GetStaticReportsResponse,
    GetStaticReportParams,
    GetStaticReportResponse,
    LoadScenariosParams,
    DeleteTemplatesParams,
    DeleteStaticReportsParams,
    FetchStaticReportsParams,
    SaveStaticReportParams,
    SaveTemplateParams,
    DownloadResultsParams,
    DownloadResultsResponse,
    CancelExecutionParams
} from "./types";

export const getStaticReportHistories = function (params: GetStaticReportHistoriesParams) {
    return fetch({
        spinnerToggle: params.spinnerToggle,
        request: () => reportSvc.getStaticReportHistories(params.filters),
        callback: (r: ReportHistoriesResponse) => params.successCallback(r.result),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });
};

export const getScenarios = (params: GetScenariosParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: GetScenariosResponse) => params.successCallback(r.result),
        request: () => reportSvc.getScenarios(params.filters),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const getTemplates = (params: GetTemplatesParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: GetTemplatesResponse) => params.successCallback(r.result),
        request: () => reportSvc.getTemplates(params.filters),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const getTemplate = (params: GetTemplateParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: GetTemplateResponse) => params.successCallback(r.result),
        request: () => reportSvc.getTemplate(params.templateId),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const fetchReportingLookup = (params: FetchReportingLookup) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: FetchReportingLookupResponse) => params.successCallback(r.result),
        request: () => lookupSvc.getReportingLookup(params.taskId),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const getStaticReports = (params: GetStaticReportsParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: GetStaticReportsResponse) => params.successCallback(r.result),
        request: () => reportSvc.getStaticReports(params.filters),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const getStaticReport = (params: GetStaticReportParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: GetStaticReportResponse) => params.successCallback(r.result),
        request: () => reportSvc.getStaticReport(params.reportId),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const loadScenarios = (params: LoadScenariosParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        request: () => reportSvc.loadResults(params.scenarioIds),
        callback: params.successCallback,
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const deleteTemplates = (params: DeleteTemplatesParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        request: () => reportSvc.deleteTemplates(params.templateIds),
        callback: params.successCallback,
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const deleteStaticReports = (params: DeleteStaticReportsParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        request: () => reportSvc.deleteStaticReports(params.reportIds),
        callback: params.successCallback,
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const fetchRunReports = (params: FetchStaticReportsParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: params.successCallback,
        request: () => reportSvc.runReportsForScenarios(params.request),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const saveStaticReport = (params: SaveStaticReportParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: params.successCallback,
        request: () => reportSvc.saveStaticReport(params.report),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const saveTemplate = (params: SaveTemplateParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: params.successCallback,
        request: () => reportSvc.saveTemplate(params.template),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const downloadResults = (params: DownloadResultsParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: (r: DownloadResultsResponse) => params.successCallback(r.result),
        request: () => reportSvc.downloadResults(params.queryExecutionIds),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });

export const cancelExecution = (params: CancelExecutionParams) =>
    fetch({
        spinnerToggle: params.spinnerToggle,
        callback: params.successCallback,
        request: () => reportSvc.cancelExecution(params.queryExecutionId),
        errorCallback: params.errorCallback,
        validationCallback: params.validationCallback,
    });