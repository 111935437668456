import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortingDirection, scenarioTypes } from 'common/enums';
import {
    ScenarioMgmtState,
    GridFilter,
    Link,
    LinkFilter,
    ScenarioEditLookups,
    ExternalFunction,
    LinkState
} from './types';
import { Task, Scenario } from 'common/types';

const initialState: ScenarioMgmtState = {
    showGridSpinner: false,
    modalHeader: 'Edit scenario',
    selectedIds: [],
    lookup: {
        items: [],
        selected: [],
    },
    gridSource: {
        data: [],
        total: 0,
        filter: {
            keywords: [],
            pageNumber: 1,
            pageSize: 60,
            orderByColumn: 'id',
            sortingDirection: sortingDirection.desc,
            scenarioTypes: scenarioTypes.allCases,
        },
    },
    linksModal: {
        open: false,
        locked: false,
        scenario: null,
        source: {
            data: [],
            total: 0,
            filter: {
                pageNumber: 1,
                pageSize: 20
            }
        },
    },
    linkModal: {
        open: false,
        locked: false,
    },
    link: {
        lookup: null,
        errors: {},
        links: null,
    },
    externalFunctions: [],
    functionModal: {
        open: false,
        locked: false,
        source: null,
    },
    task: null,
    scenarioEditModal: {
        spinner: false,
        isOpen: false,
        dashboards: [],
        dashboardGroups: [],
        partitioning: [],
        timeUnits: [],
        releases: [],
        cubeTemplates: [],
        reportTemplates: [],
        vmGroups: [],
        task: null,
        groups: [],
        scenario: null,
    },
};

const scenarioMgmtSlice = createSlice({
    name: 'scenarioManagement',
    initialState,
    reducers: {
        setGridSource(
            state,
            action: PayloadAction<{ data: Scenario[]; total: number; filter: GridFilter }>
        ) {
            state.gridSource = {
                data: action.payload.data,
                total: action.payload.total,
                filter: action.payload.filter,
            };
        },
        changeGridFilter(state, action: PayloadAction<Partial<GridFilter>>) {
            state.gridSource.filter = { ...state.gridSource.filter, ...action.payload };
        },
        toggleGridSpinner(state, action: PayloadAction<boolean>) {
            state.showGridSpinner = action.payload;
        },
        setModalHeader(state, action: PayloadAction<string>) {
            state.modalHeader = action.payload;
        },
        setTask(state, action: PayloadAction<Task>) {
            state.task = action.payload;
        },
        changeSelected(state, action: PayloadAction<number[]>) {
            state.selectedIds = action.payload;
        },
        setLookup(state, action: PayloadAction<{ label: string, value: string }[]>) {
            state.lookup.items = action.payload;
        },
        setKeywords(state, action: PayloadAction<{ label: string, value: string }[]>) {
            state.lookup.selected = action.payload;
        },
        toggleLinksModalOpen(state, action: PayloadAction<boolean>) {
            state.linksModal.open = action.payload;
        },
        toggleLinksModalLocked(state, action: PayloadAction<boolean>) {
            state.linksModal.locked = action.payload;
        },
        setLinksModalGridSource(state, action: PayloadAction<{ data: Link[]; total: number; filter?: LinkFilter }>) {
            state.linksModal.source = {
                data: action.payload.data,
                total: action.payload.total,
                filter: action.payload.filter || state.linksModal.source.filter
            };
        },
        setLinksModalScenario(state, action: PayloadAction<Scenario>) {
            state.linksModal.scenario = action.payload;
        },
        resetGridData(state) {
            state.selectedIds = [];
            state.gridSource = {
                data: [],
                total: 0,
                filter: {
                    ...state.gridSource.filter,
                    pageNumber: 1,
                    resourceAdequacyGroupIds: undefined,
                    scenarioIds: undefined,
                },
            };
        },
        toggleLinkEditModalOpen(state, action: PayloadAction<boolean>) {
            state.linkModal.open = action.payload;
        },
        toggleLinkEditModalLocked(state, action: PayloadAction<boolean>) {
            state.linkModal.locked = action.payload;
        },
        editLink(state, action: PayloadAction<Partial<Link>>) {
            state.link.links = state.link.links
                ? { ...state.link.links, ...action.payload }
                : action.payload;
        },
        setLinkLookup(state, action: PayloadAction<LinkState['lookup'] | null>) {
            state.link.lookup = action.payload;
        },
        resetLink(state) {
            state.link = initialState.link;
        },
        resetLinkModal(state) {
            state.linkModal = initialState.linkModal;
        },
        setLinkErrors(state, action: PayloadAction<{ [key: string]: string[] }>) {
            state.link.errors = action.payload;
        },
        setExternalFunctions(state, action: PayloadAction<ExternalFunction[]>) {
            state.externalFunctions = action.payload;
        },
        toggleFunctionModalOpen(state, action: PayloadAction<boolean>) {
            state.functionModal.open = action.payload;
        },
        setFunctionModalSource(state, action: PayloadAction<Partial<ExternalFunction> | null>) {
            state.functionModal.source = action.payload;
        },
        openScenarioEditModal(state) {
            state.scenarioEditModal.isOpen = true;
        },
        closeScenarioEditModal(state) {
            state.scenarioEditModal = initialState.scenarioEditModal;
        },
        setScenarioLookups(state, action: PayloadAction<ScenarioEditLookups | { spinner: boolean }>) {
            state.scenarioEditModal = { ...state.scenarioEditModal, ...action.payload, isOpen: true };
        },
        resetState(state) {
            // reset state except page size
            state = {
                ...initialState,
                gridSource: {
                    ...initialState.gridSource,
                    filter: {
                        ...initialState.gridSource.filter,
                        pageSize: state.gridSource.filter.pageSize,
                    },
                },
            };

        },
    },
});


export const actions = scenarioMgmtSlice.actions;
export default scenarioMgmtSlice.reducer;
